import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "../../../Helpers/icons/Arrow";
import { getTest } from "../../../../actions/commonAction";
import { connect } from "react-redux";
import { getMaincategoryTreeview } from "../../../../actions/homeAction";
import AllCategoriesMenu from "./AllCategoriesMenu";
import { isMobile } from 'react-device-detect';
import {
  Dropdown,
  DropdownMenuItem,
  DropdownNestedMenuItem,
} from "../../../Home/Dropdown";
import { Button } from "@mui/material";
import { ArrowRight } from "@material-ui/icons";

const Navbar = (props) => {
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState("0px");
  const [dataLength, setdataLength] = useState(5);
  const { className, type } = props;
  const navigate = useNavigate();

  useEffect(() => {
        // Fetch main category tree when the component mounts
      if(isMobile === false){
          props.getMaincategoryTreeview();
      }
    }, []);

  // const getItems = document.querySelectorAll(`.categories-list li`).length;
  // if (categoryToggle && getItems > 0) {
  //   setSize(`${40 * getItems}px`);
  // }
  const handler = () => {
    setToggle(!categoryToggle);
  };

  useEffect(() => {
    if (categoryToggle) {
      const getItems = document.querySelectorAll(`.categories-list li`).length;
      if (categoryToggle && getItems > 0) {
        setSize(`${42 * getItems}px`);
      }
    } else {
      setSize(`0px`);
    }

    // Clean-up logic can be returned in the useEffect function
    return () => {};
  }, [categoryToggle]);

  const handleNavigation = (category) => {
    navigate(`/category/${category.url}`, {
      state: { categoryData: category },
    });
  };
  return (
    <div
      className={`nav-widget-wrapper w-full  h-[60px] relative z-30 ${
        type === 3 ? "bg-qh3-blue" : "bg-qyellow"
      }  ${className || ""}`}
    >
      <div className="container-x mx-auto h-full">
        <div className="w-full h-full relative">
          <div className="w-full h-full flex justify-between items-center">
            <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
              <div className="category  h-[53px] bg-white rounded-t-md mt-[6px] relative">
                <Dropdown
                  minWidth={215}
                  isLength={dataLength}
                  allLength={props?.main_category_tree}
                  setdataLength={setdataLength}
                  trigger={
                    <Button>
                      <div className="flex space-x-3 items-center">
                        <span>
                          <svg
                            className="fill-current"
                            width="14"
                            height="9"
                            viewBox="0 0 14 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="14" height="1" />
                            <rect y="8" width="14" height="1" />
                            <rect y="4" width="10" height="1" />
                          </svg>
                        </span>
                        <span className="text-sm font-600 text-qblacktext">
                        Shop By Category
                        </span>
                      </div>
                      <div>
                        <Arrow
                          width="5.78538"
                          height="1.28564"
                          className="fill-current text-qblacktext"
                        />
                      </div>
                    </Button>
                  }
                    // ... (previous code)

                  menu={
                    Array.isArray(props?.main_category_tree) ? (
                        props?.main_category_tree?.map((item) => {
                          return item?.children
                              ?.slice(0, dataLength)
                              ?.map((child, index) => {
                                return (
                                    <DropdownNestedMenuItem
                                        key={index}
                                        label={child?.title}
                                        onClick={() => handleNavigation(child)}
                                        rightIcon={<ArrowRight />}
                                        menu={
                                          child?.children ? (
                                              child?.children?.map((secChild, secIndex) => {
                                                let ifHasNested = secChild?.children ? 1 : 0;
                                                return ifHasNested === 1 ? (
                                                    <DropdownNestedMenuItem
                                                        key={secIndex}
                                                        label={secChild?.title}
                                                        onClick={() => handleNavigation(secChild)}
                                                        rightIcon={secChild?.children ? <ArrowRight /> : null}
                                                        menu={
                                                          secChild?.children &&
                                                          secChild?.children?.map((thirdChild, thirdIndex) => {
                                                            return (
                                                                <DropdownMenuItem
                                                                    key={thirdIndex}
                                                                    onClick={() => handleNavigation(thirdChild)}
                                                                >
                                                                  <Link to={`/category/${thirdChild.url}`}>
                                                                    {thirdChild?.title}
                                                                  </Link>
                                                                </DropdownMenuItem>
                                                            );
                                                          })
                                                        }
                                                    />
                                                ) : (
                                                    <DropdownMenuItem key={secIndex}>
                                                      <Link to={`/category/${secChild.url}`}>
                                                        {secChild?.title}
                                                      </Link>
                                                    </DropdownMenuItem>
                                                );
                                              })
                                          ) : (
                                              <DropdownMenuItem key={index}>
                                                <Link to={`/category/${child.url}`}>
                                                  {child?.title}
                                                </Link>
                                              </DropdownMenuItem>
                                          )
                                        }
                                    />
                                );
                              });
                        })
                    ) : (
                        <DropdownMenuItem
                            onClick={() => {
                              console.log("clicked");
                            }}
                        >
                          Loading...
                        </DropdownMenuItem>
                    )
                  }

                />

                {/* <button
                  onClick={handler}
                  type="button"
                  className="w-full h-full flex justify-between items-center"
                >
                  <div className="flex space-x-3 items-center">
                    <span>
                      <svg
                        className="fill-current"
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="14" height="1" />
                        <rect y="8" width="14" height="1" />
                        <rect y="4" width="10" height="1" />
                      </svg>
                    </span>
                    <span className="text-sm font-600 text-qblacktext">
                      All Categories
                    </span>
                  </div>
                  <div>
                    <Arrow
                      width="5.78538"
                      height="1.28564"
                      className="fill-current text-qblacktext"
                    />
                  </div>
                </button>
                {categoryToggle && (
                  <div
                    className="fixed top-0 left-0 w-full h-full -z-10"
                    onClick={handler}
                  ></div>
                )}
                <div
                  className="category-dropdown w-full absolute left-0 top-[53px] overflow-hidden"
                  style={{ height: `${elementsSize} ` }}
                >
                  <ul className="categories-list" style={{ marginTop: "60px" }}>
                    {Array.isArray(props.main_category_tree) &&
                      props.main_category_tree.map((category) => (
                        <li className="category-item" key={category.id}>
                          {Array.isArray(category.children) && (
                            <ul className="first-nested-categories">
                              {category.children.map((first_child) => (
                                <li
                                  className="category-item"
                                  key={first_child.id}
                                >
                                  <a
                                    href={"/category/" + first_child.url}
                                    onClick={() =>
                                      handleNavigation(first_child)
                                    }
                                  >
                                    <div
                                      className={`flex justify-between items-center px-5 h-10 bg-white transition-all duration-300 ease-in-out cursor-pointer text-qblack ${
                                        type === 3
                                          ? "hover:bg-qh3-blue hover:text-white"
                                          : "hover:bg-qyellow"
                                      }`}
                                    >
                                      <div className="flex items-center space-x-6">
                                        <span>
                                          <svg
                                            className="fill-current"
                                            width="12"
                                            height="16"
                                            viewBox="0 0 12 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          ></svg>
                                        </span>
                                        <span className="text-xs font-400">
                                          {first_child.title}
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          <svg
                                            className="fill-current"
                                            width="6"
                                            height="9"
                                            viewBox="0 0 6 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            {" "}
                                          </svg>
                                        </span>
                                      </div>
                                    </div>
                                  </a>{" "}
                                  {Array.isArray(first_child.children) && (
                                    <ul
                                      className="second-nested-categories"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      {first_child.children.map(
                                        (second_child) => (
                                          <li
                                            className="category-item"
                                            key={second_child.id}
                                          >
                                            <a
                                              href={
                                                "/category/" + second_child.url
                                              }
                                              onClick={() =>
                                                handleNavigation(second_child)
                                              }
                                            >
                                              <div
                                                className={`flex justify-between items-center px-5 h-10 bg-white transition-all duration-300 ease-in-out cursor-pointer text-qblack ${
                                                  type === 3
                                                    ? "hover:bg-qh3-blue hover:text-white"
                                                    : "hover:bg-qyellow"
                                                }`}
                                              >
                                                <div className="flex items-center space-x-6">
                                                  <span>
                                                    <svg
                                                      className="fill-current"
                                                      width="12"
                                                      height="16"
                                                      viewBox="0 0 12 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      {" "}
                                                    </svg>
                                                  </span>
                                                  <span className="text-xs font-400">
                                                    {second_child.title}
                                                  </span>
                                                </div>
                                                <div>
                                                  <span>
                                                    <svg
                                                      className="fill-current"
                                                      width="6"
                                                      height="9"
                                                      viewBox="0 0 6 9"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    ></svg>
                                                  </span>
                                                </div>
                                              </div>
                                            </a>
                                            }
                                            {Array.isArray(
                                              second_child.children
                                            ) && (
                                              <ul
                                                className="third-nested-categories"
                                                style={{ marginLeft: "30px" }}
                                              >
                                                {second_child.children.map(
                                                  (third_child) => (
                                                    <li
                                                      className="category-item"
                                                      key={third_child.id}
                                                    >
                                                      <a
                                                        href={
                                                          "/category/" +
                                                          third_child.url
                                                        }
                                                        onClick={() =>
                                                          handleNavigation(
                                                            third_child
                                                          )
                                                        }
                                                      >
                                                        <div
                                                          className={`flex justify-between items-center px-5 h-10 bg-white transition-all duration-300 ease-in-out cursor-pointer text-qblack ${
                                                            type === 3
                                                              ? "hover:bg-qh3-blue hover:text-white"
                                                              : "hover:bg-qyellow"
                                                          }`}
                                                        >
                                                          <div className="flex items-center space-x-6">
                                                            <span>
                                                              <svg
                                                                className="fill-current"
                                                                width="12"
                                                                height="16"
                                                                viewBox="0 0 12 16"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              ></svg>
                                                            </span>
                                                            <span className="text-xs font-400">
                                                              {
                                                                third_child.title
                                                              }
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <span>
                                                              <svg
                                                                className="fill-current"
                                                                width="6"
                                                                height="9"
                                                                viewBox="0 0 6 9"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              ></svg>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </a>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                </div> */}
              </div>
              <div className="nav">
                <ul className="nav-wrapper flex xl:space-x-10 space-x-5">
                  {/*<li className="relative">*/}
                  {/*<span*/}
                  {/*    className={`flex items-center text-sm font-600 cursor-pointer ${*/}
                  {/*        type === 3 ? "text-white" : "text-qblacktext"*/}
                  {/*    }`}*/}
                  {/*>*/}
                  {/*  <span>Homepage</span>*/}
                  {/*  <span className="ml-1.5 ">*/}
                  {/*    <Arrow className="fill-current" />*/}
                  {/*  </span>*/}
                  {/*</span>*/}
                  {/*  <div className="sub-menu w-[220px] absolute left-0 top-[60px]">*/}
                  {/*    <div*/}
                  {/*        className="w-full bg-white flex justify-between items-center "*/}
                  {/*        style={{*/}
                  {/*          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",*/}
                  {/*        }}*/}
                  {/*    >*/}
                  {/*      <div className="categories-wrapper w-full h-full p-5">*/}
                  {/*        <div>*/}
                  {/*          <div className="category-items" style={{ marginTop: "60px" }}>*/}
                  {/*            <ul className="flex flex-col space-y-2">*/}
                  {/*              <li>*/}
                  {/*                <a href="/">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Home One*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/home-two">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Home Two*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/home-three">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Home Three*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/home-four">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Home Four*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/home-five">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Home Five*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*            </ul>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*<span*/}
                  {/*    className={`flex items-center text-sm font-600 cursor-pointer ${*/}
                  {/*        type === 3 ? "text-white" : "text-qblacktext"*/}
                  {/*    }`}*/}
                  {/*>*/}
                  {/*  <span>Shop</span>*/}
                  {/*  <span className="ml-1.5 ">*/}
                  {/*    <Arrow className="fill-current" />*/}
                  {/*  </span>*/}
                  {/*</span>*/}
                  {/*  <div className="sub-menu w-full absolute left-0 top-[60px]">*/}
                  {/*    <div*/}
                  {/*        className="mega-menu-wrapper w-full bg-white p-[30px] flex justify-between items-center "*/}
                  {/*        style={{*/}
                  {/*          minHeight: "295px",*/}
                  {/*          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",*/}
                  {/*        }}*/}
                  {/*    >*/}
                  {/*      <div className="categories-wrapper flex-1 h-full flex justify-around -ml-[70px]">*/}
                  {/*        <div>*/}
                  {/*          <div className="category">*/}
                  {/*            <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">*/}
                  {/*              Shop List*/}
                  {/*            </h1>*/}
                  {/*          </div>*/}
                  {/*          <div className="category-items">*/}
                  {/*            <ul className="flex flex-col space-y-2">*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop Sidebar*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop Fullwidth*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop Category Icon*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop Category Icon*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop List View*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*            </ul>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*          <div className="category">*/}
                  {/*            <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">*/}
                  {/*              Product Layouts*/}
                  {/*            </h1>*/}
                  {/*          </div>*/}
                  {/*          <div className="category-items">*/}
                  {/*            <ul className="flex flex-col space-y-2">*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Horizonral Thumbnail*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Vertical Thumbnail*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Gallery Thumbnail*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Sticky Summary*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*            </ul>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*          <div className="category">*/}
                  {/*            <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">*/}
                  {/*              Polular Category*/}
                  {/*            </h1>*/}
                  {/*          </div>*/}
                  {/*          <div className="category-items">*/}
                  {/*            <ul className="flex flex-col space-y-2">*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Phone & Tablet*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Gaming & Sports*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Home Appliance*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Fashion Clothes*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*            </ul>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*      <div className="thumbnil w-[348px] h-full">*/}
                  {/*        <div className="w-full h-[235px]">*/}
                  {/*          <img*/}
                  {/*              width=""*/}
                  {/*              src={`${process.env.PUBLIC_URL}/assets/images/mega-menu-thumb.jpg`}*/}
                  {/*              alt=""*/}
                  {/*              className="w-full h-full object-contain"*/}
                  {/*          />*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</li>*/}
                  {/*<li className="relative">*/}
                  {/*<span*/}
                  {/*    className={`flex items-center text-sm font-600 cursor-pointer ${*/}
                  {/*        type === 3 ? "text-white" : "text-qblacktext"*/}
                  {/*    }`}*/}
                  {/*>*/}
                  {/*  <span>Pages</span>*/}
                  {/*  <span className="ml-1.5 ">*/}
                  {/*    <Arrow className="fill-current" />*/}
                  {/*  </span>*/}
                  {/*</span>*/}
                  {/*  <div className="sub-menu w-[220px] absolute left-0 top-[60px]">*/}
                  {/*    <div*/}
                  {/*        className="w-full bg-white flex justify-between items-center "*/}
                  {/*        style={{*/}
                  {/*          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",*/}
                  {/*        }}*/}
                  {/*    >*/}
                  {/*      <div className="categories-wrapper w-full h-full p-5">*/}
                  {/*        <div>*/}
                  {/*          <div className="category-items" style={{ marginTop: "60px" }}>*/}
                  {/*            <ul className="flex flex-col space-y-2">*/}
                  {/*              <li>*/}
                  {/*                <a href="/privacy-policy">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Privacy Policy*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/terms-condition">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Terms and Conditions*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/faq">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  FAQ*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop Category Icon*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*              <li>*/}
                  {/*                <a href="/all-products">*/}
                  {/*                <span*/}
                  {/*                    className={`text-qgray text-sm font-400 border-b border-transparent   ${*/}
                  {/*                        type === 3*/}
                  {/*                            ? "hover:text-qh3-blue hover:border-qh3-blue"*/}
                  {/*                            : "hover:text-qyellow hover:border-qyellow"*/}
                  {/*                    }`}*/}
                  {/*                >*/}
                  {/*                  Shop List View*/}
                  {/*                </span>*/}
                  {/*                </a>*/}
                  {/*              </li>*/}
                  {/*            </ul>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/about">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                      >
                        <span>About</span>
                      </span>
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="/blogs">*/}
                  {/*  <span*/}
                  {/*      className={`flex items-center text-sm font-600 cursor-pointer ${*/}
                  {/*          type === 3 ? "text-white" : "text-qblacktext"*/}
                  {/*      }`}*/}
                  {/*  >*/}
                  {/*    <span>Blog</span>*/}
                  {/*  </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/contact">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                      >
                        <span>Contact</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reqforquote">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${
                          type === 3 ? "text-white" : "text-qblacktext"
                        }`}
                        aria-label="Request For Quote"
                      >
                        <span>Request For Quote</span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/*<div className="become-seller-btn">*/}
            {/*  <Link to="/become-saller">*/}
            {/*    <div className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">*/}
            {/*      <div className="flex space-x-2 items-center">*/}
            {/*        <span className="text-sm font-600">Become a Seller</span>*/}
            {/*        <span>*/}
            {/*        <svg*/}
            {/*            className="fill-current"*/}
            {/*            width="6"*/}
            {/*            height="10"*/}
            {/*            viewBox="0 0 6 10"*/}
            {/*            fill="none"*/}
            {/*            xmlns="http://www.w3.org/2000/svg"*/}
            {/*        >*/}
            {/*          <rect*/}
            {/*              x="1.08984"*/}
            {/*              width="6.94106"*/}
            {/*              height="1.54246"*/}
            {/*              transform="rotate(45 1.08984 0)"*/}
            {/*              fill="white"*/}
            {/*          />*/}
            {/*          <rect*/}
            {/*              x="6"*/}
            {/*              y="4.9082"*/}
            {/*              width="6.94106"*/}
            {/*              height="1.54246"*/}
            {/*              transform="rotate(135 6 4.9082)"*/}
            {/*              fill="white"*/}
            {/*          />*/}
            {/*        </svg>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  main_category_tree: state.home.mainCategoryTreeviewRes,
});

const mapDispatchToProps = (dispatch) => ({
  getMaincategoryTreeview: () => dispatch(getMaincategoryTreeview()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
