// import { useEffect, useState } from "react";
import datas from "../../data/products.json";
import SectionStyleFour from "../Helpers/SectionStyleFour";
import SectionStyleOne from "../Helpers/SectionStyleOne";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import Layout from "../Partials/Layout";
import Banner from "./Banner";
import BrandSection from "./BrandSection";
import { Component } from "react";
import { connect } from "react-redux";
import { getTest } from '../../actions/commonAction';
import ManufactureProducts from "./ManufactureProducts";
import SellerCategoriesProducts from "./SellerCategoriesProducts";
import ProductsAds from "./ProductsAds";
import { getFeaturedcategories, getHomeBanner } from "../../actions/homeAction";
import CampaignCountDown from "./CampaignCountDown";
import BestSellers from "./BestSellers";
import { Helmet } from "react-helmet-async";

// export default function Home() {
class Home extends Component {

    componentDidMount() {
        // this.props.getTest();
        this.props.getHomeBanner();
        this.props.getFeaturedcategories();
    }

    render() {
        // const testing    = this.props.test

        const { products } = datas;
        const topBanners = [];
        const rightLeftBanners = [];
        this.props.home_banners.data.forEach((banner) => {
            if (banner.type === "Slider Banner") {
                topBanners.push(banner);
            } else if (banner.type === "Left Banner" || banner.type === "Right Banner") {
                rightLeftBanners.push(banner.image)
            }
        });

        // const [ads, setAds] = useState(false);
        // const adsHandle = () => {
        //   setAds(false);
        // };
        // useEffect(() => gs
        // {
        //   setAds(true);
        // }, []);

        return (
            <>
                <Helmet>
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://etechbuy.com/",
                            "name": "Maximize Performance with essential Computer Components | ETechBuys.com",
                            "logo": "https://etechbuy.com/assets/images/logo.svg",
                            "description": "Discover premium-quality computer components to enhance speed, power, and efficiency, ensuring an upgraded computing experience like never before at ETechBuys.com",
                            "sameAs": [
                                "https://www.facebook.com/etechbuy.official",
                                "https://twitter.com/_ETechBuy",
                                "https://www.youtube.com/@ETechBuy"
                            ],
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "(877) 801-9898",
                                "contactType": "sales"
                            },
                            "address": {
                                "@type": "PostalAddress",
                                "postalCode": "93550",
                                "addressRegion": "California",
                                "addressLocality": "Palmdale",
                                "streetAddress": "815 E St #12709 #16318 San Diego, CA 92112",
                                "addressCountry": {
                                    "@type": "Country",
                                    "name": "United States"
                                }
                            }
                        }
                        )}
                    </script>
                </Helmet>

                <Layout childrenClasses="pt-0">
                    {/* {ads && <Ads handler={adsHandle} />} */}
                    {/*<div className="btn w-5 h-5 "></div>*/}
                    <Banner className="banner-wrapper mb-[60px]" bannerData={topBanners} />

                    <ManufactureProducts
                        sectionTitle="Top Manufacturer Products"
                        className="category-products mb-[60px]" />

                    <ProductsAds
                        ads={rightLeftBanners}
                        sectionHeight="sm:h-[295px] h-full"
                        className="products-ads-section mb-[60px]"
                    />
                    <SellerCategoriesProducts
                        sectionTitle="Top Seller Categories Products"
                        className="category-products mb-[60px]" />

                    <SectionStyleFour
                        products={this.props.featured_categories_res.data}
                        sectionTitle="Featured Categories"
                        seeMoreUrl="/all-products"
                        className="category-products mb-[60px]"
                    />

                    <BrandSection
                        sectionTitle="Shop by Manufacturer"
                        className="brand-section-wrapper mb-[60px]"
                    />
                    {/*<SectionStyleThree*/}
                    {/*    products={products}*/}
                    {/*    sectionTitle="Featured Categories"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    className="new-products mb-[60px]"*/}
                    {/*/>*/}

                    {/*<SectionStyleOne*/}
                    {/*    products={products}*/}
                    {/*    brands={brands}*/}
                    {/*    categoryTitle="Mobile & Tablet"*/}
                    {/*    sectionTitle="Gamer World"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    className="category-products mb-[60px]"*/}
                    {/*/>*/}

                    {/*<CampaignCountDown*/}
                    {/*    className="mb-[60px]"*/}
                    {/*    lastDate="2023-10-04 4:00:00"*/}
                    {/*/>*/}

                    {/*<ViewMoreTitle*/}
                    {/*    className="top-selling-product mb-[60px]"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    categoryTitle="Top Selling Products"*/}
                    {/*>*/}
                    {/*  <SectionStyleTwo products={products.slice(3, products.length)}/>*/}
                    {/*</ViewMoreTitle>*/}
                    {/*<ViewMoreTitle*/}
                    {/*    className="best-sallers-section mb-[60px]"*/}
                    {/*    seeMoreUrl="/sallers"*/}
                    {/*    categoryTitle="Best Saller"*/}
                    {/*>*/}
                    {/*  <BestSellers/>*/}
                    {/*</ViewMoreTitle>*/}

                    {/*<SectionStyleOne*/}
                    {/*    categoryBackground={`${process.env.PUBLIC_URL}/assets/images/section-category-2.jpg`}*/}
                    {/*    products={products.slice(4, products.length)}*/}
                    {/*    brands={brands}*/}
                    {/*    categoryTitle="Electronics"*/}
                    {/*    sectionTitle="Popular Sales"*/}
                    {/*    seeMoreUrl="/all-products"*/}
                    {/*    className="category-products mb-[60px]"*/}
                    {/*/>*/}
                    {/*<ProductsAds*/}
                    {/*    ads={[`${process.env.PUBLIC_URL}/assets/images/ads-3.png`]}*/}
                    {/*    className="products-ads-section mb-[60px]"*/}
                    {/*/>*/}
                    {/*<ProductsAds*/}
                    {/*    sectionHeight="164"*/}
                    {/*    ads={[`${process.env.PUBLIC_URL}/assets/images/ads-4.png`]}*/}
                    {/*    className="products-ads-section mb-[60px]"*/}
                    {/*/>*/}

                </Layout>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        test: state.home.TEST,
        home_banners: state.home.bannerRes,
        featured_categories_res: state.home.featuredcategoriesRes,
    }
}


const mapDispatchToProps = dispatch => ({
    getTest: () => dispatch(getTest()),
    getHomeBanner: () => dispatch(getHomeBanner()),
    getFeaturedcategories: () => dispatch(getFeaturedcategories()),

})

/*
 * mapStateToProps
*/


export default connect(mapStateToProps, mapDispatchToProps)(Home);
