import { Link } from "react-router-dom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function Orderprocess() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="about-page-wrapper w-full">
        <div className="title-area w-full">
          <PageTitle
            title="Order Process"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Order Process", path: "/order-process" },
            ]}
          />
        </div>

        <div className="aboutus-wrapper w-full">
          <div className="container-x mx-auto">
            <div className="w-full min-h-[665px] lg:flex lg:space-x-12 items-center pb-10 lg:pb-0">
              <div className="content flex-1 mt-5">
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  ETechBuys.com brings you an all-in-one platform for all your IT
                  requirements, offering an extensive range of products from top
                  brands at competitive prices. With our offices and warehouses
                  in the US, Germany, and China, we ensure delivery across the
                  world.
                </p>
                <h6 className="text-[18px] font-medium text-qblack mb-2.5 mt-3">
                  Here is how you can order at ETechBuys.com.
                </h6>

                <ul className="text-[15px] text-qgraytwo leading-7 list-disc ml-5 mb-2">
                  <li>
                    Visit ETechBuys.com to find your desired product from our
                    catalog of products.
                  </li>
                  <li>
                    Once you have found your desired product, add it to your
                    cart and proceed to check out.
                  </li>
                  <li>
                    Add your delivery address by selecting your delivery country
                    and option
                  </li>
                  <li>Add your shipping and payment details</li>
                  <li>Now, to confirm your order, click on “Place Order.”</li>
                </ul>

                <h6 className="text-[18px] font-medium text-qblack mb-2.5 ">
                  Payment Methods:
                </h6>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  We offer a variety of payment options to accommodate your
                  preferences and requirements. You have the flexibility to
                  choose from:
                </p>
                <ul className="text-[15px] text-qgraytwo leading-7 list-disc ml-5 mb-2">
                  <li>
                    Credit and Debit Cards: We accept American Express, Visa,
                    Discover, and MasterCard through a secure payment gateway.
                    (Please note: Your card will be charged on the day of your
                    order for security reasons.).
                  </li>
                  <li>
                    Bank Transfer: You can also pay by bank transfer (wire
                    transfer) if you prefer. To ensure a smooth transaction, we
                    will need to verify your identity before we process your
                    order.
                  </li>
                  <li>
                    Net Terms Payment: If you qualify for ETechBuys.com's net
                    terms, you can take advantage of this payment option, which
                    allows you to place orders with a flexible payment period of
                    up to 30 days.
                  </li>
                </ul>

                <h6 className="text-[18px] font-medium text-qblack mb-2.5 ">
                  International Order Processing
                </h6>

                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  Once you have placed an international order, our team will
                  verify your order information and ensure that all the details
                  are completed and can be processed. In case of any missing or
                  additional information, you may also be contacted by our team
                  at the provided email or number.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  Once the order has been processed, it will be shipped through
                  the delivery option you have chosen. Also, you will receive an
                  order tracking number so you can track your order through
                  every step.
                </p>

                <h6 className="text-[18px] font-medium text-qblack mb-2.5 ">
                  International Order Delivery
                </h6>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  The delivery time for international orders varies depending on
                  your location and the service provider. However, most
                  international orders are delivered within 10-12 business days.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  The shipping costs for international orders do not include
                  taxes or duties, and you may have to pay additional duties and
                  taxes upon receiving the order.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  Some international orders may need additional variations,
                  causing a slight delay in shipping. This verification is
                  necessary to ensure that the right order is delivered at the
                  right location and to avoid any issues with possible returns.{" "}
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mb-5">
                  In case of any changes, after the order has been placed, you
                  will be notified through the contact details you have
                  provided. You will have the choice to cancel your order and
                  get a full refund or continue with the order at the updated
                  price.
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
