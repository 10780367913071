import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Youtube from "../../../Helpers/icons/Youtube";
import Twitter from "../../../Helpers/icons/Twitter";
import Pinterest from "../../../Helpers/icons/Pinterest";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Footer({ type }) {
  return (
    <>
      <Helmet>
        <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js" type="text/javascript" />
      </Helmet>
      <footer className="footer-section-wrapper print:hidden">
        <div className="container-x block mx-auto pt-[56px]">
          <div className="w-full flex flex-col items-center ">
            {/* logo area */}
            <div className="mb-[40px]">
              {type === 3 ? (
                <Link to={"/"}>
                  <img

                    width="152"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                    alt="logo"
                  />
                </Link>
              ) : (
                <Link to={"/"}>
                  <img

                    width="452"
                    height="36"
                    src={`${process.env.PUBLIC_URL}/assets/images/ETechBuy-Logo.svg`}
                    alt="logo"
                  />
                </Link>
              )}
            </div>
            <div className="w-full h-[1px] bg-[#000]"></div>
          </div>
          <div className="lg:flex justify-between ">
            <div className="flex-1 lg:flex footerMobile mt-5">
              <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
                <div className="mb-4">
                  <h6 className="text-[18] font-700 text-[#2F2F2F]">
                    Featured Categories
                  </h6>
                </div>
                <div>
                  <ul className="flex flex-col space-y-2">
                    <li>
                      <Link to={"/category/storage-devices/hard-drives.html"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Hard Drives
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/category/memory.html"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Memory
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/category/storage-devices/solid-state-drives.html"}
                      >
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Solid State Drives
                        </span>
                      </Link>
                    </li>

                    <li>
                      <Link to={"/category/processors.html"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Processors
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/category/network-and-accessories.html"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Network & Accessories
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/category/power-equipment/power-supplies.html"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Power Supplies
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/category/motherboards.html"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Motherboards
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
                <div className="mb-4">
                  <h6 className="text-[18] font-700 text-[#2F2F2F]">
                    Information
                  </h6>
                </div>
                <div>
                  <ul className="flex flex-col space-y-2">
                    <li>
                      <Link to={"/privacy-policy"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Privacy Policy
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/return-policies"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Return Policy
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/warranty"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Warranty Info
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/shipping"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Shipping
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/taxexempt"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Tax Exempt
                        </span>
                      </Link>
                    </li>

                    <li>
                      <Link to={"/payment-method"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Payment Methods
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/faq"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          FAQs
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/terms-condition"}>
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Terms & Conditions
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
                <div>
                  <div className="mb-4">
                    <h6 className="text-[18] font-700 text-[#2F2F2F]">
                      Customer Account
                    </h6>
                  </div>
                  <div>
                    <ul className="flex flex-col space-y-2">
                      <li>
                        <Link to={"/login"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            Login
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/signup"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            Register
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/profile"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            My Account
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/cart"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            Shopping Cart
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/order-process"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            Order Process
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/services"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            Our Services
                          </span>
                        </Link>
                      </li>

                      <li>
                        <Link to={"/about"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            About Us
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/contact"}>
                          <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                            Contact Us
                          </span>
                        </Link>
                      </li>
                      {/* <li>
                      <Link to={"/">
                        <span className="text-[#000] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Blog
                        </span>
                      </a>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* 424 */}
            <div className="lg:w-[330px] bg-[rgba(86,165,157,.2)] ml-0 w-full lg:mb-0 pt-5 pl-5 pb-4">
              {/* <img
                                    width="152"
                                    height="36"
                                    src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                                    alt="logo"
                                /> */}
              <h2 className="text-[18] font-700 text-[#2F2F2F] ">Address</h2>
              <p className="text-[#000] text-[15px] w-[247px] leading-[28px]">
                815 E St #12709  <br />
                #16318 San Diego, CA 92112
              </p>
              <h2 className="text-[18] font-700 text-[#2F2F2F] mt-1">Phone</h2>
              <p className="text-[#000] text-[15px] w-[247px] leading-[28px]">
                <a href="tel:1-877-801-9898"> (877) 801-9898</a>
              </p>
              <h2 className="text-[18] font-700 text-[#2F2F2F] mt-1">Email</h2>
              <p className="text-[#000] text-[15px] w-[247px] leading-[28px]">
                <Link to={"mailto:support@etechbuys.com"}>
                  {" "}
                  support@etechbuys.com
                </Link>
                <br />
                <Link to={"mailto:sales@etechbuys.com"}> sales@etechbuys.com</Link>
                <br />
                <Link to={"mailto:rma@etechbuys.com"}> rma@etechbuys.com</Link>
                <br />
              </p>
              <h2 className="text-[18] font-700 text-[#2F2F2F] mt-1">
                Working Hours
              </h2>
              <p className="text-[#000] text-[15px] w-[247px] leading-[28px]">
                Mon-Fri / 8:00 AM- 5:00 PM (PST)
              </p>
              <div className="footerTag">
                <ul className="footerDiv">
                  <li>
                    <Link to={"/about"}>About Us</Link>
                  </li>
                  <li>
                    <Link to={"/services"}>Services</Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#000]"></div>
          <div className="bottom-bar mt-3 lg:h-[82px] lg:flex justify-between items-center pb-3">
            <div className="flex lg:space-x-5 justify-between items-center mb-3">
              <div className="flex space-x-5 items-center">

                <Link to={"https://www.instagram.com/etechbuy"} target="_blank" aria-label="Visit our Instagram Page">
                  <Instagram className="fill-current text-qgray hover:text-qblack" />
                </Link>
                <Link
                  to={"https://www.facebook.com/etechbuy.official"}
                  target="_blank"
                  aria-label="Visit our Facebook Page"
                >
                  <Facebook className="fill-current text-qgray hover:text-qblack" />
                </Link>
                <Link to={"https://www.youtube.com/@ETechBuy"} target="_blank" aria-label="Visit our Youtube Channel">
                  <Youtube className="fill-current text-qgray hover:text-qblack" />
                </Link>
                <Link to={"https://twitter.com/_ETechBuy"} target="_blank" aria-label="Visit our Twitter Page">
                  <Twitter className="fill-current text-qgray hover:text-qblack" />
                </Link>
                <Link to={"https://www.pinterest.com/etechbuy/"} target="_blank" aria-label="Visit our Pinterest Page">
                  <Pinterest className="fill-current text-qgray hover:text-qblack" />
                </Link>
              </div>
              <span className="sm:text-base text-[10px] text-[#000] font-300">
                ©2024
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-500 text-qblack mx-1"
                >
                  ETechBuys
                </a>
                All rights reserved
              </span>
            </div>
            <div className="flex lg:space-x-5 justify-center items-center">
              <a
                href="//www.dmca.com/Protection/Status.aspx?ID=14141680-60af-40f7-b203-e3bda20a7f58"
                title="DMCA.com Protection Status"
                className="dmca-badge"
              >
                <img
                  src="https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=14141680-60af-40f7-b203-e3bda20a7f58"
                  alt="DMCA.com Protection Status"
                />
              </a>
              <Link to={"#"}>
                <img

                  width="210"
                  height="28"
                  src={`${process.env.PUBLIC_URL}/assets/images/payment-getways.png`}
                  alt="payment-getways"
                />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>

  );
}
