import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import {orderUpdate, resetOrder} from "../../actions/commonAction";
import { connect } from "react-redux";
import {PaymentElement,useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useShoppingCart} from "use-shopping-cart";
// const stripeSk = "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";
const stripeSk = "";

const Thankyou = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [orderId, setOrderId] = useState("");
  const { cartDetails, clearCart, removeItem, cartCount, totalPrice } = useShoppingCart();


  // useEffect(() => {
  //   props.resetOrder();
  //
  //   if(props.order_res?.status === 201){
  //   }else{
  //     navigate('/')
  //   }
  // }, [props.resetOrder, props.order_res]);

  // useEffect(() => {
  //     console.log(props.order_update_res,'okokokok')
  // }, [props.order_update_res]);

  useEffect(()=>{

    const urlParams = new URLSearchParams(window.location.search);
    const document_id = urlParams.get("document_id");
    if(document_id){
      setDocumentId(document_id)
    }else{
      const user_data = JSON.parse(location.state?.orderData);
      setDocumentId(user_data?.order.data.data.document_id)
    }
    const payment_intent = urlParams.get("payment_intent");
    const order_id = urlParams.get("order_id");
    setOrderId(order_id);

    const fetchPaymentIntent = async (payment_intent,order_id) => {

      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer '+stripeSk
        },
        redirect: 'follow'
      };

      try {
        const response = await fetch("https://api.stripe.com/v1/payment_intents/"+payment_intent, requestOptions);
        const result = await response.json();
        const { status, amount_received } = result;

        if(status === "succeeded"){
          const data = {
            payment_response : result,
            paid_amount : amount_received/100,
            payment_status : true
          }
          props.orderUpdate(order_id,data)
        }else{
          const data = {
            payment_response : result,
            paid_amount : 0,
            payment_status : false
          }
          props.orderUpdate(order_id,data)
        }
        clearCart();
      } catch (error) {
        console.log('error', error);
      }
    };

    if(payment_intent !== null && order_id !== null){
      fetchPaymentIntent(payment_intent,order_id);
    }

  },[])

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="tracking-page-wrapper w-full">
        {/* <div className="page-title mb-[40px]">
            <PageTitle
                title="Thank you"
                breadcrumb={[
                  { name: 'home', path: '/' },
                  { name: 'Thank you', path: '/thank-you' },
                ]}
            />
          </div> */}
        <div className="content-wrapper w-full mb-[40px]">
          <div className="container-x mx-auto">
            {/* <h1 className="text-[22px] text-qblack font-semibold leading-9">
                Thank you for shopping with us.
              </h1>
              <p className="text-[15px] text-qgraytwo leading-8 mb-5">
                we've successfully received your order and sent you a confirmation email.
                <br></br>
                Your order reference number is: {location.state?.orderData?.order?.data?.document_id}
              </p>
              <div className="w-full bg-white lg:px-[30px] px-5 py-[23px] lg:flex items-center">
                <a href="/">
                  <div className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                    <span className="text-sm font-semibold">Continue Shopping</span>
                  </div>
                </a>
              </div> */}
            <div className="orderBox">
              <h1 className="text-[26px] text-qblack font-semibold leading-9">
                Your Order has been received
              </h1>
              <img
                
                width="72"
                height="72"
                src={`${process.env.PUBLIC_URL}/assets/images/thankstick.png`}
                alt="thankyou"
              />
              <h4 className="text-[22px] text-qblack leading-9 font-semibold">Thank you for your purchase</h4>
              <p className="text-[17px] text-qblack leading-9 font-semibold">
                Your Order ID is:{documentId}
                {/*{location.state?.orderData?.order?.data?.document_id}*/}
              </p>
              <h6 className="text-[16px] text-qblack leading-9 font-semibold">
                You will receive an order confirmation email with your order
                details.
              </h6>
             
                <a href="/">
                  <div className="w-[220px] h-[50px] bg-[#56a59d] flex justify-center items-center">
                    <span className="text-sm font-semibold">
                      Continue Shopping
                    </span>
                  </div>
                </a>
             
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  order_res: state.common.orderRes,
  order_update_res: state.common.orderUpdateRes,
});
const mapDispatchToProps = (dispatch) => ({
  resetOrder: () => dispatch(resetOrder()),
  orderUpdate: (id,data) => dispatch(orderUpdate(id,data)),
});

export default connect(null, mapDispatchToProps)(Thankyou);
