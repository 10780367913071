import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Arrow from "../../../Helpers/icons/Arrow";
import { getHomeBanner, getHomeNavbar } from "../../../../actions/homeAction";
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';

const NavbarSecond = (props) => {
  const { className, type, navbar_res, getHomeNavbar } = props;
  const [categoryToggle, setToggle] = useState(false);
  const [elementsSize, setSize] = useState("0px");
  const [displayedItems, setDisplayedItems] = useState(5); // Number of items initially displayed
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
      getHomeNavbar();
    }
  }, [isMobile]);

  const handler = () => {
    setToggle(!categoryToggle);
  };

  useEffect(() => {
    const getItems = document.querySelectorAll(`.categories-list li`).length;
    setSize(categoryToggle && getItems > 0 ? `${42 * getItems}px` : `0px`);
  }, [categoryToggle]);

  const handleNavigation = (data, parentData) => {
    navigate(`/categories/${data.url}`);
  };

  const handleSeeMore = () => {
    // Increase the number of displayed items by a certain amount
    setDisplayedItems(prev => prev + 5);
  };

  return (
      <div className={`nav-widget-wrapper w-full h-[60px] relative z-30 ${type === 3 ? "bg-qh3-blue" : "bg-white"} ${className || ""} border border-solid border-black flex items-center justify-between`}>
        <div className="container-x mx-auto h-full">
          <div className="w-full h-full relative">
            <div className="w-full h-full flex justify-between items-center">
              <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
                <div className="nav">
                  <ul className="nav-wrapper flex xl:space-x-8 space-x-5">
                    {Array.isArray(navbar_res) && navbar_res.map(navbar => (
                        <li key={navbar.title}>
                      <span className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-white" : "text-qblacktext"}`}>
                        <span>{navbar.title}</span>
                        <span className="ml-1.5 ">
                          <Arrow className="fill-current" />
                        </span>
                      </span>
                          <div className="sub-menu w-full absolute left-0 top-[60px]">
                            <div className="mega-menu-wrapper w-full bg-white p-[30px] flex justify-between items-center " style={{ minHeight: "85px", boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)" }}>
                              <div className="categories-wrapper w-full h-full flex ">
                                {Array.isArray(navbar.children) && navbar.children.map((navbarFirstChildren, index) => (
                                    <div key={index}>
                                      <div className="category" style={{ paddingLeft: "10px" }}>
                                        <strong className="text-[13px] font-700 text-qblack uppercase mb-[13px]">
                                          {navbarFirstChildren.title}
                                        </strong>
                                      </div>
                                      <div className="category-items" style={{ paddingLeft: "10px" }}>
                                        <ul className="flex flex-col space-y-2">
                                          {navbarFirstChildren.children?.slice(0, displayedItems).map((navbarSecondChildren, subIndex) => (
                                              <li key={subIndex}>
                                        <span  style={{ cursor: "pointer" }} onClick={() => handleNavigation(navbarSecondChildren, navbarFirstChildren)} className={`text-qgray text-sm font-400 border-b border-transparent spanText ${type === 3 ? "hover:text-qh3-blue hover:border-qh3-blue" : "hover:text-qyellow hover:border-qyellow"}`}>
                                          {navbarSecondChildren.title}
                                        </span>
                                              </li>
                                          ))}
                                        </ul>
                                        {navbarFirstChildren.children?.length > displayedItems && (
                                            <button  className='text-sm font-bold h-full addtoCart Morebtn' onClick={handleSeeMore}>
                                             More...
                                            </button>
                                        )}
                                      </div>
                                    </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

const mapStateToProps = state => ({
  navbar_res: state.home.navbarRes,
});

const mapDispatchToProps = dispatch => ({
  getHomeNavbar: () => dispatch(getHomeNavbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarSecond);
