import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";

export default function PrivacyPolicy() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Privacy Policy | Making Computing Better | ETechBuys.com </title>
        <meta
          property="og:title"
          content={"Privacy Policy | Making Computing Better | ETechBuys.com "}
        />
        <meta
          name="title"
          content={"Privacy Policy | Making Computing Better | ETechBuys.com "}
        />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Privacy Policy", path: "privacy-policy" },
              ]}
              title="Privacy Policy"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-2">
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com (the "Company") is the exclusive owner of all
                  information collected on ETechBuys.com. This information is not
                  sold, shared, or rented to third parties except as outlined in
                  this policy.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The data collected from customers is solely used for order
                  processing and improving customer service. This includes order
                  confirmation, status updates, and providing information on
                  products or services. We may also use the information to
                  understand individual shopping experiences and research
                  customer buying habits.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The collected information includes customer names, billing and
                  shipping addresses, contact numbers, email addresses, and
                  payment details for order processing. Customers are required
                  to create a username <strong>support@etechbuys.com</strong> and
                  password for future account access. It's essential to keep
                  this information confidential and not share it with others.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  If customers opt to receive promotional materials, surveys,
                  and newsletters, we use the provided contact information to
                  deliver the latest updates and promotional offers. Even if
                  customers choose not to receive newsletters, promotional
                  materials, or surveys, transactional information may still be
                  sent to maintain the customer relationship. This includes
                  updates to policies, business methods, requests for
                  information review, and details about previous transactions.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  We may gather aggregated information on customers from
                  third-party sources, ensuring compliance with applicable laws
                  and limitations imposed by third-party providers, though not
                  necessarily reflected in this privacy statement.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Information Collection and Order Processing</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  To place an order on ETechBuys.com, users need to complete the
                  New Account Registration form, providing contact information,
                  billing, and shipping addresses, email, and phone numbers.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This information is used for order processing, order status
                  updates, and important shipping/tracking information.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Ordering Process</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  During order placement, financial details like credit card
                  number, expiration date, CSV code, wire transfer, purchase
                  order, money order, and required check information are used
                  solely for billing to fulfill the order.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In case of order processing issues, contact information is
                    used to confirm details.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Personal and financial data are shared with the merchant
                    bank for authorization.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The entire process is encrypted and protected by enhanced
                    security measures.
                  </li>
                </ul>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Use of Cookies</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Webpages are customized based on collected cookie data
                        for a relevant customer experience.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Disabling cookies in the browser prevents the use of the
                        shopping cart and order placement.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        com shopping cart requires enabled cookies to function.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Personally identifiable information is not shared with
                        third parties during site use.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Information may be provided to law enforcement only in
                        cases of site abuse.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Limited access or control over cookies is used by
                        business partners, affiliates, manufacturers, and
                        advertisers on the site.
                      </li>
                    </ul>
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Use of Log Files</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        IP addresses are recorded in log files for trend
                        analysis and demographic information.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        IP addresses are not linked to personally identifiable
                        information.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Third Parties</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Third-party services are employed for various business
                        functions.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Third-party associates have limited access to personal
                        information and are restricted from using it for other
                        purposes.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Customer information is considered a business asset,
                        subject to transfer in acquisitions or sales.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Personally identifiable information is used in
                        accordance with the existing Privacy Policy during such
                        business changes.
                      </li>
                    </ul>
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Volunteering Information</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users can voluntarily share information or create public
                        profiles in the community section.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Information may include product reviews, opinions, forum
                        posts, chat messages, files, and photo sharing.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Certain personally identifiable information agreed upon
                        by users can be made public, such as screen names,
                        images, or shared personal details.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Testimonials in the community section are moderated for
                        accuracy, positivity, and genuineness.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The company reserves the right to edit, alter, or remove
                        personal information violating laws or infringing on
                        rights.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        By posting testimonials, users permit the company to
                        reprint and use them for business purposes.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Conditions For Testimonial Use</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Reprint/use at the company's sole discretion without
                        compensation
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Local, national, and global use across various media and
                        communication methods.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Testimonials must be posted by the user, not by someone
                        else.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Affiliates, partners, parents, and subsidiaries can use
                        testimonials for their respective businesses.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The company may edit or alter testimonials to remove
                        personal information, law-violating statements, or
                        statements infringing on rights.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Personal contact information, other than a used pseudo
                        name, will not be disclosed in connection with
                        testimonial reprint or use.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Referral Service</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users can refer friends, family, or colleagues using the
                        referral service.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Use by Children</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The site is not intended for children; products are for
                        purchase by adults only.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users under 18 must use the site in the presence of a
                        consenting parent or guardian. We will not knowingly or
                        intentionally collect information via the Internet from
                        children ages 13 or under. At ETechBuys.com, we are
                        committed to the protection, welfare, and privacy of
                        children.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>External Links</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        com contains links to external sites. The company is not
                        responsible for the privacy practices of these linked
                        sites. Users are encouraged to read the privacy policies
                        of each visited site that collects personally
                        identifiable information. This privacy statement applies
                        only to information collected on ETechBuys.com.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Security Recommendations</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The company recommends using a security-enabled browser
                        for online transactions.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Information submitted during purchases is encrypted,
                        enhancing security.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        A complete key icon at the bottom left indicates a
                        secure environment.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        A lack of secure transaction support in the browser may
                        expose information to others.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The company follows strict policies, procedures, and
                        guidelines to safeguard customer information.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Personal information, including credit card numbers,
                        entered in the New Customer Registration Form is
                        encrypted.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        SSL (Secure Sockets Layer) technology is employed for
                        encryption.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Company servers, including web and database servers, are
                        in secure locations.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Database access is monitored and protected against
                        external access.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Internet access is restricted and safeguarded by
                        multiple firewalls.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Access to restricted areas is limited to personnel with
                        proper security clearance.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Today's Specials and Offers</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Specials and offers are posted daily, and customers can
                        opt out of receiving them.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Review and Update Information:
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Customers can review and update personal information in
                        the "My Account" section.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Opt-Out</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users can opt out of receiving alerts, special offers,
                        and communications through various methods.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        An opt-out link is provided in all communications.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Information Collection and Order Processing:
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        To place an order on ETechBuys.com .com, users need to
                        complete the New Account Registration form, providing
                        contact information, billing, and shipping addresses,
                        email, and phone numbers.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        This information is used for order processing, order
                        status updates, and important shipping/tracking
                        information.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Ordering Process</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Financial information, such as credit card details, is
                        collected during order placement for billing purposes.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Contact information is used to resolve any order
                        processing issues.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Use of Cookies</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Some webpages are customized based on collected cookie
                        information.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Disabling cookies may limit site functionality,
                        including the shopping cart.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Use of Log Files</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        IP addresses are recorded in log files for trend
                        analysis and demographic information.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        IP addresses are not linked to personally identifiable
                        information.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Third Parties</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Third-party services, such as shipping, order tracking,
                        and credit card processing, may be used for order
                        fulfillment.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Limited access is granted to third parties, and they may
                        not use information for other purposes.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Volunteering Information</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users may create public profiles, post testimonials, and
                        share information in community sections.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Shared information may include screen names, images, or
                        personal details.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Referral Service</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users can refer friends, family, or colleagues using the
                        referral service.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Use by Children</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The site is not intended for children; products are for
                        purchase by adults only.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users under 18 must use the site in the presence of a
                        consenting parent or guardian.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>External Links</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        com is not responsible for the privacy practices of
                        linked external sites.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Security Recommendations</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users are advised to use a security-enabled browser for
                        encrypted information transmission.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The company employs SSL technology for secure
                        transactions.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Today's Specials and Offers</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Specials and offers are posted daily, and customers can
                        opt out of receiving them.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Review and Update Information</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Customers can review and update personal information in
                        the "My Account" section.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Opt-Out</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Users can opt out of receiving alerts, special offers,
                        and communications through various methods.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        An opt-out link is provided in all communications.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Changes to Privacy Policy</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        com reserves the right to modify its privacy policy, and
                        users are encouraged to review it frequently.
                      </li>
                    </ul>
                  </li>
                </ul>
                <div id="pip-toast">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
