import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";


export default function PaymentMethod() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Payment Method | Making Computing Better | ETechBuys.com </title>
        <meta property="og:title" content={'Payment Method | Making Computing Better | ETechBuys.com  '} />
        <meta name="title" content={'Payment Method | Making Computing Better | ETechBuys.com  '} />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Payment Method", path: "/payment-method" },
              ]}
              title="Payment Method"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <h6 className="text-[15px] text-black font-medium leading-7">
                  We accept the following forms of payment:
                </h6>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Credit Cards
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">Visa</li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    MasterCard
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    American Express
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Discover
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Bank Transfers / Wire Transfer
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Net Credit&nbsp;
                  </li>
                </ul>
                <p className="text-[15px] text-black font-medium leading-7">
                  Payment Methods Not Accepted:
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    We do not accept payments through Paypal, cheques, or cash
                    on delivery.
                  </li>
                </ul>
                <h6 className="text-[16px] font-medium text-qblack mb-2.5 mt-2">
                  Contact Information:
                </h6>
                <p className="text-[15px] text-qgraytwo leading-7">
                  {" "}
                  You can reach us through the following contact methods:
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Phone Number: (877) 801-9898
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Address:  815 E St #12709
                  <br />
                  #16318 San Diego, CA 92112
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Email: support@etechbuys.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
