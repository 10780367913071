import React, {useEffect, useState} from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";
import {passwordChange, resetPassword} from "../../actions/customerAction";
import {connect} from "react-redux";
import {toast} from "react-toastify";

import { useNavigate, Link } from 'react-router-dom';

const ResetPassword = (props) => {
        // For Scroll Top

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
    });

    const [formErrorData, setFormErrorData] = useState({
        email_error: "",
        password_error: "",
        password_confirmation_error: "",
        token_error: "",

    });

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
        scrollToTop();
    }, []);
    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        // If the type is checkbox, set the value to the boolean checked state
        const newValue = type === "checkbox" ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    function getTokenFromURL(url) {
        const urlParams = new URLSearchParams(url.split('?')[1]);
        return urlParams.get('token');
    }


    const handleSubmit = () => {
        //token get from url
        // Get the token
        const currentUrl = window.location.href;
        const token = getTokenFromURL(currentUrl);

        const data = {
            email: formData.email,
            password: formData.password,
            password_confirmation: formData.password_confirmation,
            token: token
        };

        props.resetPassword(data);
    };

    useEffect(() => {
        if (props.rest_res?.data?.success === true ) {
            toast.success("Password reset link sent successfully", {
                position: "top-right",
                autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });


            navigate('/login')
        }else if (props.rest_res?.data) {

            setFormErrorData((prevData) => ({
                ...prevData,
                email_error: props.rest_res.data["email"]
                    ? props.rest_res.data["email"][0]
                    : "",
                password_error: props.rest_res.data["password"]
                    ? props.rest_res.data["password"][0]
                    : "",
                password_confirmation_error: props.rest_res.data["password_confirmation"]
                    ? props.rest_res.data["password_confirmation"][0]
                    : "",
                token_error: props.rest_res.data["token"]
                    ? props.rest_res.data["token"][0]
                    : "",
            }));
        }else if(props.rest_res?.message){
            toast.error(props.rest_res?.message, {
                position: "top-right",
                autoClose: 3000, // Close the notification after 3000 milliseconds (3 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

    },[props.rest_res])

    return (
        <>
            <Layout childrenClasses="pt-0 pb-0">
                <div className="terms-condition-page w-full bg-white pb-[30px]">
                    <div className="w-full mb-[30px]">
                        <PageTitle
                            breadcrumb={[
                                { name: "home", path: "/" },
                                { name: "Reset password", path: "/reset-password" },
                            ]}
                            title="Reset password"
                        />
                    </div>
                    <div className="w-full">
                        <div className="container-x mx-auto">
                            <div className="content-item w-full mb-10">
                                <div className="flex space-x-8">
                                    <div className="w-[570px] ">
                                        <div className="input-item flex space-x-2.5 mb-8">
                                            <div className="w-1/2">
                                                <label htmlFor="email">
                                                    Email*
                                                </label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    placeholder="email"
                                                    value={formData.email} // Uncomment this line
                                                    onChange={handleChange}
                                                    className="w-full h-[50px] border border-[#EDEDED] px-2"
                                                />
                                                <span className="text-danger">
                                                  {formErrorData.email_error}
                                                </span>
                                            </div>
                                            <div className="w-1/2">
                                                <label htmlFor="password">
                                                    Password*
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type={"password"}
                                                    placeholder="password"
                                                    value={formData.password} // Uncomment this line
                                                    onChange={handleChange}
                                                    className="w-full h-[50px] border border-[#EDEDED] px-2"
                                                />
                                                <span className="text-danger">
                                                  {formErrorData.password_error}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="input-item flex space-x-2.5 mb-8">
                                            <div className="w-1/2">
                                                <label htmlFor="password_confirmation">
                                                      Confirm Password*
                                                </label>
                                                <input
                                                    id="password_confirmation"
                                                    name="password_confirmation"
                                                    type="password"
                                                    placeholder="password_confirmation"
                                                    value={formData.password_confirmation} // Uncomment this line
                                                    onChange={handleChange}
                                                    className="w-full h-[50px] border border-[#EDEDED] px-2"
                                                />
                                                <span className="text-danger">
                                                  {formErrorData.password_confirmation_error}
                                                  {formErrorData.token_error}
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="action-area flex space-x-4 items-center">
                                    <button
                                        type="button"
                                        onClick={handleSubmit}
                                        className="w-[164px] h-[50px] bg-qblack text-white text-sm"
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

const mapStateToProps = (state) => ({
    rest_res: state.customer.restRes,
});

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (data) => dispatch(resetPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
