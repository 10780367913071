import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {city, countries, fedex, orderNow, resetOrder, states, websitepaymentmethods, websiteshippingmethods} from "../../actions/commonAction";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {useShoppingCart} from "use-shopping-cart";
import "../CheakoutPage/stripeStyle.css";
import {loadStripe} from "@stripe/stripe-js";


const CheckoutForm = (props) => {
        const { setCardPaymentStatus,cardPaymentStatus,amount } = props;
        const { cartDetails, clearCart, removeItem, cartCount, totalPrice } = useShoppingCart();

        const stripe = useStripe();
        const elements = useElements();

        const [message, setMessage] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        const [orderCreate, setOrderCreate] = useState(true);
        const [againOrder, setAgainOrder] = useState(true);


        const handleSubmitCart = async (e) => {
            e.preventDefault();
            if (!stripe || !elements) {
                // Stripe.js hasn't yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }


            // when the state change than order will be created. setCardPaymentStatus state must be change to hit checkout createSubmit function in checkout index page
            if(orderCreate){
                if(cardPaymentStatus){
                    setCardPaymentStatus(false);
                }else {
                    setCardPaymentStatus(true);
                }

            }else{
                setAgainOrder(false)
            }
            setIsLoading(true);

        };

        useEffect(()=>{
            const fetchData = async() => {
                if (props.order_res?.status === 201) {
                    if (props.order_res && Object.keys(props.order_res).length > 0) {
                        const { error } = await stripe.confirmPayment({
                            elements,
                            confirmParams: {
                                // Make sure to change this to your payment completion page
                                return_url: `https://etechbuy.com/thank-you?document_id=${props.order_res?.order?.data?.document_id}&order_id=${props.order_res?.order?.data?.id}`,
                            },
                        });



                        // This point will only be reached if there is an immediate error when
                        // confirming the payment. Otherwise, your customer will be redirected to
                        // your `return_url`. For some payment methods like iDEAL, your customer will
                        // be redirected to an intermediate site first to authorize the payment, then
                        // redirected to the `return_url`.
                        if (error.type === "card_error" || error.type === "validation_error") {
                            setMessage(error.message);
                            setOrderCreate(false);
                        } else {
                            setMessage("An unexpected error occurred.");
                        }
                        setIsLoading(false);

                    }
                }else {
                    setIsLoading(false);
                    setAgainOrder(true)
                }

            }
            fetchData();
        },[props.order_res,againOrder])

    //     useEffect(() => {
    //     if (!stripe) {
    //         return;
    //     }
    //
    //     const clientSecret = new URLSearchParams(window.location.search).get(
    //         "payment_intent_client_secret"
    //     );
    //
    //     if (!clientSecret) {
    //         return;
    //     }
    //
    //     // const urlParams = new URLSearchParams(window.location.search);
    //     // const documentId = urlParams.get("document_id");
    //     // const orderId = urlParams.get("orderid");
    //     //
    //     // console.log("Document ID:", documentId);
    //     // console.log("Order ID:", orderId);
    //
    //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //         alert(paymentIntent.status)
    //         switch (paymentIntent.status) {
    //             case "succeeded":
    //                 console.log("Payment succeeded!");
    //                 break;
    //             case "processing":
    //                 console.log("Your payment is processing.");
    //                 break;
    //             case "requires_payment_method":
    //                 console.log("Your payment was not successful, please try again.");
    //                 break;
    //             default:
    //                 console.log("Something went wrong.");
    //                 break;
    //         }
    //     });
    // }, [stripe]);

        const paymentElementOptions = {
            layout: "tabs"
        }

        return (
            <>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit" onClick={handleSubmitCart} className="payBtn">
        <span id="button-text" >
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </>
        );
}


const mapStateToProps = (state) => ({
    order_res: state.common.orderRes,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
