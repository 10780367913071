import { getHomeBanner, getHomeBrands } from "../../actions/homeAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CarouselSlider from "react-carousel-slider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BrandSection = (props) => {
  const { className, sectionTitle, type } = props;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch main category tree when the component mounts
    props.getHomeBrands();
  }, []);

  const handleNavigation = (brand) => {
    navigate(`/manufacturer/${brand.url}`, { state: { brandData: brand } });
  };

  let data = [
    {
      des: "1",
      imgSrc: "{`${process.env.PUBLIC_URL}/assets/images/brand-1.png`}",
    },
    {
      des: "2",
      imgSrc: "{`${process.env.PUBLIC_URL}/assets/images/brand-2.png`}",
    },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  let ImagesData =
    props && props?.home_brands?.data?.length > 0
      ? props?.home_brands?.data?.map((item, index) => {
          return {
            des: index,
            imgSrc: item?.manufacturer?.image,
            brand: item,
          };
        })
      : data;
  // console.log(data, "brand");
  let customSlideCpnts = ImagesData?.map((item, index) => (
    <div
      key={index}
      onClick={() => handleNavigation(item?.brand)}
      style={{ height: 100, width: 150, cursor: "pointer" }}
    >
      <img
        style={{ height: "100%", width: "100%" }}
        src={item?.imgSrc}
        alt="Shop By Manufacture"
        
      />
    </div>
  ));

  // let itemsStyle = {
  //   height: 100,
  //   padding: "0px",
  //   background: "transparent",
  // };

  // let buttonSetting = {
  //   placeOn: "middle-inside",
  //   hoverEvent: true,
  //   style: {
  //     left: {
  //       height: "50px",
  //       width: "50px",
  //       color: "#929393",
  //       background: "rgba(225, 228, 232, 0.8)",
  //       borderRadius: "50%",
  //     },
  //     right: {
  //       height: "50px",
  //       width: "50px",
  //       color: "#929393",
  //       background: "rgba(225, 228, 232, 0.8)",
  //       borderRadius: "50%",
  //     },
  //   },
  // };

  // let manner = {
  //   autoSliding: { interval: "2s" },
  //   duration: "1s",
  // };

  return (
    <div data-aos="fade-up" className={`w-full ${className || ""}`}>
      <div className="container-x mx-auto">
        <div className=" section-title flex justify-between items-center mb-5">
          <div>
            <h2 className="sm:text-3xl text-xl font-600 text-qblacktext">
              {sectionTitle}
            </h2>
          </div>
        </div>
        <div className="grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-2">
          {/* {Array.isArray(props.home_brands.data) && props.home_brands.data.map(brand => (
                <div className="item" key={brand.url} onClick={()=>handleNavigation(brand)}>
                    <div className="w-full h-[130px] bg-white border border-primarygray flex justify-center items-center">
                      <img src={`${brand.manufacturer.image}`} alt="logo" />
                    </div>
                </div>
            ))} */}
        </div>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={1000}
          infinite={true}
        >
          {ImagesData?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleNavigation(item?.brand)}
              style={{ height: 100, width: 150, cursor: "pointer" }}
            >
              <img
                style={{ height: "100%", width: "100%" }}
                src={item?.imgSrc}
                alt="Shop By Manufacture"
               
              />
            </div>
          ))}
          {/* <div>Item 1</div>
          <div>Item 2</div>
          <div>Item 3</div>
          <div>Item 4</div> */}
        </Carousel>

        {/* {props?.home_brands?.data?.length > 0 && (
          <CarouselSlider
            manner={manner}
            itemsStyle={itemsStyle}
            buttonSetting={buttonSetting}
            slideCpnts={customSlideCpnts}
          />
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  home_brands: state.home.brandsRes,
});

const mapDispatchToProps = (dispatch) => ({
  getHomeBrands: () => dispatch(getHomeBrands(20)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandSection);
