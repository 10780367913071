// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    baseURL: process.env.REACT_APP_BASE_URL
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = process.env.REACT_APP_JWT_TOKEN;
instance.defaults.headers.common['Content-Type'] ='application/json';
// instance.defaults.headers.common['Content-Type'] ='multipart/form-data';
instance.defaults.headers.common['Accept'] ='application/json';
instance.defaults.headers.common['Access-Control-Allow-Origin'] ='*';
instance.defaults.headers.common['Access-Control-Allow-Credentials']  = false;
instance.defaults.headers.common['Access-Control-Allow-Methods'] ='GET,PUT,POST,DELETE,PATCH,OPTIONS';
instance.defaults.headers.common['Access-Control-Allow-Headers'] ='Origin, X-Requested-With, Content-Type, Accept';
// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default instance;