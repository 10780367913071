import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";

export default function Shipping() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Shipping | Making Computing Better | ETechBuys.com </title>
        <meta property="og:title" content={'Shipping | Making Computing Better | ETechBuys.com '} />
        <meta name="title" content={'Shipping | Making Computing Better | ETechBuys.com '} />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Shipping", path: "/shipping" },
              ]}
              title="Shipping"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com ensures timely delivery of every order. Here are
                  a few of the common concerns regarding shipping:
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Tracking Number</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  If there are problems with your tracking number, then probably
                  one of the packages was disconnected from the order. It is
                  advisable to wait for a couple of days until all your packages
                  are delivered. If you find any inconsistencies, please contact
                  our shipping department for assistance. For packages that you
                  think may have been damaged in transit, please contact the
                  carrier directly to resolve this issue.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Scheduling and Delivery Concerns</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  If the delivery time is considered inconvenient for you, refer
                  to the Info Notice you have received. This document may
                  include alternatives for rescheduling your delivery.
                  Alternatively, you may leave these details for the delivery
                  driver along with additional instructions or use it through
                  your carrier&rsquo;s online platform to make changes in time
                  and perhaps also location.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>FedEx </strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The company ETechBuys.com uses{" "}
                  <strong>FedEx International</strong> when it ships
                  internationally. But the customers will be responsible for
                  customs and duties applied to their orders.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  To contact FedEx: 1-800-463-3339 |
                  https://www.fedex.com/global/choose-location.html
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>
                      Shipping to APO (Army Post Office) Or FPO (Fleet Post
                      Office) Addresses
                    </strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com does deliver to APO and FPO addresses. APO and
                  FPO orders will only be shipped by the US postal service.
                  Unfortunately, ETechBuys.com cannot control the delivery time
                  of such orders. Some of these orders can take six to eight
                  weeks to deliver. Know that APO and FPO orders generally take
                  five to ten working days to deliver.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Free Standard Shipping</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Orders are typically dispatched within 24-48 hours and
                  delivered based on our standard delivery schedule for the US
                  which is four to six business days, respectively seven to ten
                  business days for international orders once processed. Note
                  that free shipping does not apply to Alaska and Hawaii, along
                  with some areas where no ground shipments are available inside
                  the US. For this service, we have partnered with FedEx, and
                  deliveries are made between 8 a.m. to 8 p.m., depending on the
                  respective distance for ground shipments
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Please note that any products, such as Lithium-Ion batteries,
                  etc., cannot be delivered by air and are only delivered
                  through ground shipping.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Shipping and Cut-Off Timing </strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  When placing an order with ETechBuys.com, you have an array of
                  shipping options to fit your needs. Here&rsquo;s a breakdown
                  of each, along with the final order time (PST) to ensure
                  same-day processing:
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          <strong>Delivery Options </strong>
                        </p>
                      </td>
                      <td width="172">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          <strong>Shipping Method</strong>
                        </p>
                      </td>
                      <td width="139">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          <strong>Cut-off Time</strong>
                        </p>
                      </td>
                      <td width="166">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          <strong>Terms &amp; Conditions</strong>
                        </p>
                      </td>
                      <td width="132">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          <strong>Handling Time </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          3-7 Days Delivery
                        </p>
                      </td>
                      <td rowspan="6" width="172">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          FedEx &amp; UPS
                        </p>
                      </td>
                      <td rowspan="5" width="139">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          2 P.M PST
                        </p>
                        <p className="text-[15px] text-qgraytwo leading-7">
                          &nbsp;
                        </p>
                      </td>
                      <td rowspan="5" width="166">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Applicable for the U.S. only.
                        </p>
                      </td>
                      <td rowspan="6" width="132">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          1-2 Business Days
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          3-Day Delivery
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          2-Day Delivery
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Next-Day Standard Delivery
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Priority Next-Day Delivery
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Weekend
                        </p>
                      </td>
                      <td width="139">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          12 P.M Noon
                        </p>
                      </td>
                      <td width="166">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Saturday delivery in the U.S.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          International Deliveries
                        </p>
                      </td>
                      <td width="172">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          FedEx International
                        </p>
                      </td>
                      <td width="139">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          2 P.M PST
                        </p>
                      </td>
                      <td width="166">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          International Orders (transit in 7-10 business days)
                        </p>
                      </td>
                      <td width="132">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          3 to 5 business days
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Military Addresses (APO/FPO)
                        </p>
                      </td>
                      <td width="172">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Sent via Registered Mail
                        </p>
                      </td>
                      <td width="139">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          2 P.M PST
                        </p>
                      </td>
                      <td width="166">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Timing May Vary
                        </p>
                      </td>
                      <td width="132">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          1-2 Business Days
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="171">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Canadian and Puerto Rican Orders
                        </p>
                      </td>
                      <td width="172">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          FedEx International
                        </p>
                      </td>
                      <td width="139">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          2 P.M PST
                        </p>
                        <p className="text-[15px] text-qgraytwo leading-7">
                          &nbsp;
                        </p>
                      </td>
                      <td width="166">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          Timing May Vary
                        </p>
                      </td>
                      <td width="132">
                        <p className="text-[15px] text-qgraytwo leading-7">
                          3 to 5 business days
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  For special shipping needs beyond these options, please get in
                  touch with our sales department at (877) 801-9898
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Handling Time </strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Handling time is the difference between the order cutoff time
                  following the placement of an order and when the shipment for
                  the order is ready for transit (hand off to carrier).
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Shipping Delays</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  We do not process or ship orders on weekends or major
                  holidays. While we strive to ship orders on the same day, we
                  cannot guarantee this.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Missing Items</strong>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  If you find an item missing from your order, please get in
                  touch with the ETechBuys.com Shipping Support Staff at (877) 801-9898 or email us at support@etechbuys.com
                </p>
                <div id="pip-toast">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
