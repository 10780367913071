import {commonConstants, customerConstants, homeConstants, orderConstants} from "../constants";
import axiosConfig from '../axiosConfig'
import axios from 'axios';

const websiteId  = 1;
export const signup = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/`+websiteId+`/websitecustomers`,jsonobj);
        let result = {
            status: res.status,
            sign_up_data: res.data,
        };
        dispatch({
            type: customerConstants.SIGNUP,
            payload: result, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: customerConstants.SIGNUP_ERROR,
            payload: e.response.data,
        });
    }
};

export const updateProfile = (id,jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.put(`/websites/`+websiteId+`/websitecustomers/${id}`,jsonobj);
        let result = {
            status: res.status,
            profile_data: res.data,
        };
        dispatch({
            type: customerConstants.PROFILE_UPDATE,
            payload: result, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: customerConstants.PROFILE_UPDATE_ERROR,
            payload: e.response.data,
        });
    }
};


export const login = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/`+websiteId+`/websitecustomers/auth/login`,jsonobj);
        dispatch({
            type: customerConstants.LOGIN,
            payload: res, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: customerConstants.LOGIN_ERROR,
            payload: e.response.data,
        });
    }
};

export const resetLogin = () => ({
    type: customerConstants.RESET_LOGIN,
});

export const getCustomerOrders = (jwtToken) => async (dispatch) => {
    try {
        const userData = JSON.parse(localStorage.getItem('user_data'));
        let  userId = userData?.user?.id
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteorders?filter[website_customer_id]=${userId}&per_page=9999`);
        dispatch({
            type: customerConstants.CUSTOMER_ORDER,
            payload: res.data,
        });
    } catch (e) {
        dispatch({
            type: customerConstants.CUSTOMER_ORDER_ERROR,
            payload: console.log(e),
        });
    }
};

export const passwordChange = (jsonobj) => async (dispatch) => {
    try {

        const userData = JSON.parse(localStorage.getItem('user_data'));
        const instance = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            headers: {
                // 'Authorization': "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYTMyMDU1YmI0OWVlZDdiMDg0NTYzZDU2N2ExNmFhZDMxNGRkZWVkNWFmZWU1OTZkM2YwMTkwMzczMDM0NGMyMGIyM2Y0ODVmNzUyYmQ0OTkiLCJpYXQiOjE3MDk1NTY5NTEuMTU4MjA2LCJuYmYiOjE3MDk1NTY5NTEuMTU4MjEzLCJleHAiOjE3NDEwOTI5NTEuMTQxOTQ3LCJzdWIiOiIxNSIsInNjb3BlcyI6W119.I2ce6Q1cxD937k9TgWTzAzrBEieqAraYcZLV5MSDTgbJCuQ5TjKzJIVLBFxmT1JFdV3QJ9nGVZUHl-oJ09HFdzFzoHeJajvtbv_lZbnPn2oCcDVmjiCsxr0QsWap_jzRAOOFf2P18b2ZrWfkjWAiAB6rmLGHgWp0emAHec0ge9ICBV_yhCN34O-ikbQv35QvhiJh6qrdwqkpzfbG9S2CIsRcK-g1c6goNVWG8eBEXQa8tII18rW2henT3_Ttf20NbZJeAGLYkf5SQV1kbYb9dE29Y38yO8h5biYWze2Vvyt3ka2L2gHkJj3O_hY28jAfcOu7bVnSQNmq2zVm9dOzD6xQgERMZ-nKzx099pikoCNMfiGfiRLkqpYqnSYvjQcjMll7KAhcIdXBogKq4TXfJnpfTZq2isCw8IjwBWmOrVFlcnBxAhASynYvUNNj1B5tDU4cLSaCillCQby7nF2zItvI3Y7LTuxcZYh72g2w6zHDzporyFR5B7gxS_Pn3q2h7BcjVOqE3v8gFNDaRtBXCnJH_6kl68cfhfzinF65kGpdDrIGUdhrviAvTjCXIKSyzQNoRjNSJMAYp4YObjh-yNP3OKZq7OdzVCYXJnL4AhYXwkX1EEzcHmTmAWr_GkAs92d2zNza5u-ebmIXw9wBEgPquiLIv1fnE426uQ3Jtu4",
                'Authorization': "Bearer "+userData?.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': false,
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            }
        });
        const res = await instance.post(`/websites/`+websiteId+`/websitecustomers/auth/changepassword`,jsonobj);
        dispatch({
            type: customerConstants.PASSWORD_CHANGE,
            payload: res, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: customerConstants.PASSWORD_CHANGE_ERROR,
            payload: e.response.data,
        });
    }

};

export const forgotPassword = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/`+websiteId+`/websitecustomers/auth/forgotpassword`,jsonobj);
        dispatch({
            type: customerConstants.FORGOT_PASSWORD,
            payload: res, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: customerConstants.FORGOT_PASSWORD_ERROR,
            payload: e.response.data,
        });
    }
}

export const resetPassword = (jsonobj) => async (dispatch) => {
    try {
        const res = await axiosConfig.post(`/websites/`+websiteId+`/websitecustomers/auth/resetpassword`,jsonobj);
        dispatch({
            type: customerConstants.RESET_PASSWORD,
            payload: res, // Assuming the response data is what you want to dispatch
        });
    } catch (e) {
        // Dispatch the error action
        dispatch({
            type: customerConstants.RESET_PASSWORD_ERROR,
            payload: e.response.data,
        });
    }
}