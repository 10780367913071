import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";

export default function Taxexempt() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Tax Exempt | Making Computing Better | ETechBuys.com </title>
        <meta
          property="og:title"
          content={"Tax Exempt | Making Computing Better | ETechBuys.com"}
        />
        <meta
          name="title"
          content={"Tax Exempt | Making Computing Better | ETechBuys.com"}
        />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Tax Exempt", path: "/taxexempt" },
              ]}
              title="Tax Exempt"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <p className="text-[15px] text-qgraytwo leading-7">
                  The purpose of this Tax-Exempt policy is to provide a clear
                  and comprehensive overview of the process and criteria
                  necessary to obtain tax exemptions when making purchases
                  through ETechBuys.com.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Tax Exemptions</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>State Tax Exemption</strong> implies that all
                    states, except for the state of California, use tax
                    exemptions on purchases. Although purchases in California
                    can be subject to state spending taxes that are often 9.5%
                    and may vary by county.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Tax-exempt purchases may include{" "}
                    <strong>nonprofit organizations</strong> such as charitable
                    organizations, religious groups, and educational
                    establishments.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    When procurement of goods and services is done through
                    ETechBuys.com, tax exemptions could be possible for both
                    federal and <strong>state governmental agencies</strong>.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Tax-exempt status may also be granted to{" "}
                    <strong>reseller businesses</strong> provided that they have
                    filled and filed all the requisite documents.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Application Procedure</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Tax exemption application procedure varies for new and
                  returning customers. Following is all the information you may
                  need about the application procedure;
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    To ensure a streamlined process for tax exemption requests,{" "}
                    <strong>new customers</strong> are required to create an
                    account on our website. This account serves as the primary
                    interface for submitting tax exemption requests. Before
                    initiating the application, it is essential to compile the
                    necessary documents such as certificates or forms that
                    establish your organization's tax-exempt status. Once your
                    account is activated and the required documents are
                    prepared, kindly email us at support@etechbuys.com with your
                    account information or order number for reference. This
                    ensures a smooth and efficient handling of your tax
                    exemption request.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    For <strong>returning customers</strong> who have previously
                    secured tax exemption status, the process is notably more
                    straightforward. To sustain your tax-exempt status, it is
                    important to periodically renew documentation. In the event
                    of any changes in your tax exemption status or expiration of
                    relevant documents, we kindly request that you submit the
                    updated paperwork promptly. This proactive approach ensures
                    the continual compliance of your account, facilitating a
                    seamless and efficient experience for all returning
                    customers in managing their tax-exempt status.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>For Multi-Jurisdictional Exemptions</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    For organizations operating across multiple states, it is
                    essential to provide the necessary documentation required
                    for compliance in each state. When placing orders, kindly
                    specify the relevant state for which you are seeking tax
                    exemption during the checkout process. To ensure ongoing
                    regulatory compliance, we will diligently retain and manage
                    your documentation on record. This proactive approach
                    enables us to apply for tax exemptions as needed for future
                    orders, guaranteeing adherence to the varying tax
                    regulations across different jurisdictions. Your cooperation
                    in providing accurate information during the order process
                    contributes to the seamless and compliant handling of
                    multi-state submissions.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
