import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";

export default function Services() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Services | Making Computing Better | ETechBuys.com</title>
        <meta property="og:title" content={'Services | Making Computing Better | ETechBuys.com'} />
        <meta name="title" content={'Services | Making Computing Better | ETechBuys.com'} />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Services", path: "/services" },
              ]}
              title="Services"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <h6 className="text-[24px] font-medium text-qblack mb-2">
                  OUR SERVICES:
                </h6>

                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com is your one-stop destination for IT solutions and
                  hardware products. We specialize in computer memory upgrades
                  and offer a vast selection of computer hardware products,
                  including hard drives, memory modules, power supplies, CPUs,
                  and various accessories. Our extensive database covers
                  products compatible with major brands such as Dell, HP,
                  Lenovo, Apple, Acer, Cisco, Compaq, IBM, Epson, Hitachi, and
                  many more.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Our mission at ETechBuys.com is to support customers worldwide
                  in optimizing their businesses by providing top-quality
                  products and services that enhance the performance of newer
                  machines and extend the lifespan of older ones, promoting
                  sustainability and cost-efficiency. We prioritize our
                  customers' satisfaction and offer competitive prices sourced
                  directly from trusted suppliers and manufacturers. We take
                  pride in ensuring that all products listed on our website,
                  ETechBuys.com, are accurately described and categorized for a
                  transparent shopping experience. Furthermore, each product
                  undergoes rigorous testing before shipping to ensure its
                  reliability and functionality. Your success is our priority,
                  and we're committed to delivering the best IT solutions and
                  hardware products to meet your needs.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Electronic devices make life easier, but to get the most out
                  of the technologies available, you also need to select the
                  best hardware to increase system performance. We present you
                  with the best selections to pick from here at ETechBuys.com.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>International Orders</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com encourages International Orders. As we ship
                  across the globe, we have different charges based on location
                  and product size.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>How to Check Shipping Rate?</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  To determine the international shipping cost as per your
                  destination, you can add the products to your cart and proceed
                  to check out. There, you can see the shipping rates before the
                  order process is completed. Please note that items heavier
                  than 5 lbs. will not be charged for shipping; however, any
                  products weighing less than 5 lbs. will be charged.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com ensures that customers have a seamless shopping
                  experience. Hence, we do not charge any handling fees from our
                  customers. But, if any country's customs impose customs fees,
                  the customer will bear them. Although we firmly believe in
                  timely shipment, several circumstances could cause a delivery
                  delay. Regular customers are eligible to order without any
                  difficulties or delays.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  We appreciate providing services to our International
                  customers, but specific scenarios lead to the verification of
                  that International cardholder being limited outside of the
                  home country.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Mode of Payment</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Due to the high degree of fraudulent credit cards that we have
                  received from International customers, we must reserve the
                  right to either restrict, delay, or even void any credit card
                  purchases billed or shipped to foreign addresses. We offer our
                  customers various means to pay us, which includes American
                  Express, Discover, Visa, or MasterCard, although these cards
                  can take up to 7 business days to get verified. Please note
                  that PayPal, cash-on-delivery, and cheques are not available
                  for payment.
                </p>

                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Our Hardware Products</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  After undergoing rigorous testing, we dispatch our hardware
                  products to our clients to provide the best possible IT and
                  hardware solutions. With our hardware items, you can choose
                  from our updated product database, which has been updated in
                  response to feedback from our devoted customer base. These
                  hardware products can serve as a means to optimize the
                  efficiency of your device while extending the useful life of
                  your older devices. At the same time, they can also provide
                  you with an opportunity to accelerate the productivity of your
                  newer machines.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Some of the Hardware products that we offer can be categorized
                  as follows:
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Accessories and batteries.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Cables and Adapters.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Audio and Video.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Motherboards.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Power Supplies.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Networking Devices.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Printers and Scanners.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    PC, Servers, and Software.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Storage Devices.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    CPUs and Processors.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  You can check the sub-categories of each of the categories
                  mentioned above on ETechBuys.com.com today to see which
                  hardware product best fits the needs of your device.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Our IT Solutions</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Today's business world demands software solutions that can
                  oscillate from creating completely new systems to modifying
                  the existing ones. Working as a team, we can help you find the
                  best IT solutions, as our team takes up the responsibility of
                  providing you with:
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    IT Security.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    IT Auditing.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    IT Infrastructure Setup.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Surveillance.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Unified Communication.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>IT Security</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  We assist you in making sure that your IT systems are safe
                  from potential dangers here at ETechBuys.com. This can be a
                  difficult task requiring specialized knowledge, time, and
                  resources, all of which our knowledgeable IT staff can offer
                  you. We can assist you in achieving your aim of tailoring to
                  specific business requirements, even though this might be a
                  challenging procedure to carry out. Ultimately, every
                  corporation wants to secure corporate data, which is why
                  ETechBuys.com is here to handle all of your security needs.
                  However, in certain situations, this protection may cause
                  delays.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Strategies to Secure Data</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com has had the privilege of offering their clients
                  top-line IT hardware products with security services using
                  firewalls and building strategies to protect an enterprise's
                  network from a malicious intruder.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Network Reliance</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com holds a vast line of high-profile wireless
                  routers and LAN cards such as Cisco, D-Link, Gigabyte
                  Technology, IBM, Intel, and Juniper Networks, with most
                  reliant network adaptors and switches of the world&rsquo;s
                  leading IT &ndash; hardware companies such as Buffalo
                  Technology, D-Link, Intel, Oracle Corporation, QLogic and
                  Realtek, etc. protecting your network&rsquo;s security on a
                  whole new level.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Integrated Security Framework</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  We aim not just to integrate your network security with
                  hardware products of the world&rsquo;s top-notch IT companies
                  such as Kingston, Crucial, Micron, Samsung, Hynix, HP, IBM,
                  Lenovo, and Dell but also promise you to deliver the quality
                  you need in order to enhance secure data transfer speed and
                  portability.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>IT Auditing</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Audits can be a crucial step in ensuring the optimized
                  functioning of all the IT assets owned by an organization. The
                  auditors required in this domain need to be skilled and
                  experienced so that they can evaluate the assets in the best
                  possible manner. Most organizations consult third-party
                  companies to fulfill this task, but this is precisely where
                  the problem lies. Most auditors need to be better seasoned in
                  their conduct and also omit the basic principles of auditing
                  while dealing with IT auditing. We do not stick to a
                  particular approach and leave out the others, but here at
                  ETechBuys.com, we are dexterous in all three approaches.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Technological Innovation Process Audit</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  In this audit, the auditor creates a risk profile for the
                  company by assessing its current and future projects with
                  regard to its experience in the field, the industry, and the
                  market.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Innovative Comparison Audit</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This audit compares the innovative capabilities of the company
                  in question with its competitors. It examines the research and
                  development of the company as well.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Technological Position Audit</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  In this audit, the company&rsquo;s current technologies are
                  reviewed along with the ones it would need to add.
                  Technologies are characterized as being either "base," "key,"
                  "pacing," or "emerging." Our IT auditors are well versed in
                  conducting the audit with respect to all the approaches and
                  also the categories of IT audit.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Systems and Applications</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This audit verifies that the system and applications used by
                  the organization are efficient and adequately controlled to
                  ensure valid, reliable, timely, and secure input, processing,
                  and output at all levels of a system's activity.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Information Processing Facility</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This audit ensures that the processing facility is controlled
                  to ensure timely, accurate, and efficient processing of
                  applications under normal and potentially disruptive
                  conditions.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>System Development</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This audit ensures that the system under development meets the
                  objectives of the company and that its development is per
                  generally accepted standards for systems development.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Management of IT and Enterprise Architecture</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This audit ensures that the IT Management has provided a set
                  of structure and procedures to ensure a controlled and
                  efficient environment for information processing.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>
                    Client/Server, Telecommunications, Intranets, And Extranets
                  </strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  This audit ensures that telecommunications controls are in
                  place on the client (computer receiving services), server, and
                  the network connecting the clients and servers.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>IT Infrastructure Setup</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Another complex and daunting challenge that awaits a company
                  is setting up its IT infrastructure. A number of aspects
                  demand consideration before one even considers starting the
                  process. There is the communications aspect, which dictates
                  the medium of the communication, either landline or VoIP, and
                  the network aspect, which dictates either opting for a leased
                  line or VPN. Determining all this is a tough task to pull off,
                  but not for us. We here at ETechBuys.com excel at providing IT
                  infrastructure consultancy and implementation along with our
                  top-of-the-line quality hardware products.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Implementation</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The technical team at ETechBuys.com provides implementation
                  support that ensures compatibility and good performance of all
                  the IT parts in your IT system. The process of implementation
                  asks for proper planning to accommodate scalability for future
                  growth and allow optimized space occupation. Our field of
                  excellence revolves around ensuring fast and organized setup
                  along with methodical cable of implementation.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Network</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Network infrastructure holds an eminent position on the list
                  of key components of any office architecture. It facilitates
                  the corporate operations to function with each other and
                  access the internet. In the absence of any such network, the
                  office routine would cease to function. The network engineers
                  at ETechBuys.com aim to provide you with the best option to opt
                  for as the network infrastructure solution for your company,
                  keeping in perspective the budget and resources.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Data Centre</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The data requirements vary from company to company, and they
                  dictate the data center solution they deploy. Starting from
                  Tape drives and media to internal hard drives and RAID
                  controllers, we have had the pleasure of implementing and
                  maintaining all sorts of data storage components. Our
                  expertise comes into action while dealing with SAN (Storage
                  Area Network) and network storage devices.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Security</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The network security solutions at ETechBuys.com range from
                  firewalls, router and switch security, endpoint security, VPN
                  access solutions, etc. Our security experts will not use a ONE
                  APPROACH TO FIT ALL strategy. Still, they will recommend the
                  best possible solution for your business that coincides with
                  your specified operational budget.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>SAN Storage</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  A storage area Network, more commonly known as SAN storage, is
                  a local network of multiple devices operating on a disk block.
                  This data storage technique is emerging as a handy tool for
                  enterprise organizations. It comprises hosts, switches,
                  storage elements, and storage devices, which are
                  interconnected using a variety of technologies, topologies,
                  and protocols. SANs are the most common storage networking
                  architecture opted by enterprises, and the credit goes to the
                  attribute of delivering high throughput and low latency for
                  business-critical applications. Moreover, SAN enables
                  organizations to apply consistent methodologies and tools that
                  would aid in security, data protection, and disaster recovery.
                  Engineers at ETechBuys.com take pride in providing the best
                  possible implementation of SAN devices. The major benefits of
                  SANs can be described as follows:
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Renowned for Scalability</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  SAN is widely renowned for its scalability. SAN scalability
                  refers to the scenario where you do not need to limit your
                  system in terms of being attached to a system. SANs can grow
                  to hundreds of disks in size, whereas your server would be
                  limited to about a dozen.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Unhindered Performance</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The unhindered performance by SAN is another key benefit of
                  SAN, which is because data transmitted from a SAN is on its
                  private network partitioned off from user traffic, backup
                  traffic, and other SAN traffic. The performance is not
                  affected by local disk throughput bottlenecks or Ethernet
                  traffic.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Isolation of Data</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  The isolation of data refers to the point that the data
                  visibility scheme is extremely secure in SAN servers. The
                  possibility of your data being copied or stolen is extremely
                  low and can be well understood from the fact that even SAN
                  admins cannot view your data. SAN prevents overlapping by
                  storing data in different zones on the same SAN. An evident
                  example is that both Windows and UNIX servers can connect to
                  the same SAN but still cannot access each other&rsquo;s data.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Long Distance Connectivity</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  SAN provides long-range connectivity over a distance of 10km
                  (about 6 miles). This enables many companies to distance their
                  storage units far away using fiber channel technology or other
                  network protocols. That makes it more feasible for companies
                  to keep their backup data in remote locations.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Business Continuity Management</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  SAN storage plays an important role in an organization&rsquo;s
                  Business Continuity Management (BCM) activities. Also, SAN
                  improves application availability and enhances application
                  performance. The storage arrays in SAN use excellent data
                  protection algorithms to make sure that data stays consistent.
                  SAN also simplifies storage administration and adds
                  flexibility since cables and storage devices do not need to be
                  locomoted to shift storage from one server to another.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Surveillance Solutions</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Securing your business prospects along with upholding the
                  integrity of your data can be achieved through a surveillance
                  system that can safeguard your business&rsquo;s meaningful
                  information and activities. To avoid a malicious threat or
                  activity that may bring any risk to your business, a
                  surveillance system is in dire need.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com has got your back when it comes to providing you
                  with a wide range of industry-leading IP cameras and other
                  network-managing devices that would bring reliability and
                  functionality to your business. We at ETechBuys.com are here to
                  help you keep up with the ever-growing security challenges of
                  the future.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Reliable and Secure Storage</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  With the growing dynamics of business, your security
                  surveillance needs to become more sophisticated and advanced,
                  and this is precisely where our role comes into action. We
                  provide you with the solution of an IP video surveillance
                  system that can securely and reliably scale across a
                  campus-wide LAN or Wireless LAN, outdoors with Mesh
                  connectivity, and even across the internet for remote
                  locations. ETechBuys.com uses RAID controllers, Tape Media,
                  SSDs, and other network storage belonging to the best quality
                  hardware products to provide scalable storage devices and data
                  protection.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Camera Support</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  In order to monitor the activities carried out within your
                  organization, having an integrated digital system can help you
                  view the surveillance feeds. ETechBuys.com supports various
                  specialized cameras such as Dome IP Security Cameras,
                  Multi-lens, PTZ, Night vision IR Cameras, Network Cameras,
                  Dome IP Security Cameras, and many more to the business to
                  deploy cameras suited best to their needs.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Distributed Intelligence</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Our IP-based video surveillance caters to distributed
                  intelligence that features video motion detection and audio
                  detection corresponding to the events recorded. A vast range
                  of high-quality door controllers is easily integrated with our
                  provided IP Cameras, allowing security personnel to remotely
                  manage building entrances, centralize logs, and record on a
                  single portal.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>Unified Communications</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Unified Communications (UC) helps you to interact with
                  everyone in your workforce. Businesses rely on the appropriate
                  use of technology for communication, which makes it difficult
                  for the functional areas to collaborate and operate smoothly,
                  which is required to overcome the limits of the organization
                  as a whole.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuys.com aims to ensure the productivity and profitability
                  of your organization, which provides you with a smoother, more
                  steadfast, and more secure network.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mt-2">
                  <strong>How Do We Do So?</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  We do so by offering the following services:
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuys.com offers you all the communication hardware
                    solutions depending upon the needs and requirements of the
                    organization, which would bring ease to the UC strategy.
                    Choosing the right router network switches and other
                    essential components can help you access and manage emails.
                    It provides you with a single point of interface in order to
                    meet and collaborate.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuys.com provides you with products featuring
                    industry-leading engineering and cost-efficient network
                    management, which can allow flexible deployment and
                    virtualized options. ETechBuys.com aims to provide you with
                    support at every location through its unrivaled scalability
                    and flexibility.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuys.com devices as your hardware solution can take you
                    forward toward a vast and reliable network through
                    simplifying purchasing with all-in-user-based licensing. It,
                    therefore, helps your team to work faster, more effectively,
                    and productively.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
