import React from 'react';
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider explicitly
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store'; // Import your updated store
import App from './App';
import 'aos/dist/aos.css';
import './index.css';
import AOS from 'aos';
import { CartProvider } from 'use-shopping-cart';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css';
AOS.init();

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <CartProvider>
            <HelmetProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
                <ToastContainer />
            </HelmetProvider>
        </CartProvider>
    </Provider>
);

