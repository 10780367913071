import { useEffect ,useState} from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Routers from "./Routers";

function App() {
  const location = useLocation();
  const { pathname, search } = location;
  useEffect(() => {
    if (location.pathname === "/home-two") {
      document.body.classList.add("home-two");
    } else if (location.pathname === "/home-four") {
      document.body.classList.add("home-four");
    } else if (location.pathname === "/home-five") {
      document.body.classList.add("home-five");
    } else if (location.pathname === "/") {
      document.body.classList.remove("home-two");
      document.body.classList.add("home-one");
    } else {
      document.body.classList.add("home-one");
    }

    return () => {
      document.body.classList.remove("home-two");
      document.body.classList.remove("home-four");
      document.body.classList.remove("home-five");
      document.body.classList.add("home-one");
    };
  }, [location.pathname]);

  const [canonicalURL, setCanonicalURL] = useState('');

  useEffect(() => {
    const currentURL = window.location.origin + pathname + search;

    let canonicalURL = currentURL;

    if (!window.location.hostname.startsWith("www.")) {
      canonicalURL = "https://www." + window.location.hostname + pathname + search;
    }

    setCanonicalURL(canonicalURL);
  }, [location.pathname]);

  return (
    <>
      <Helmet defaultTitle='Maximize Performance with essential Computer Components | ETechBuy.com'>
        <meta name="title" content="Maximize Performance with essential Computer Components | ETechBuy.com" />
        <meta name="description" content="Discover premium-quality computer components to enhance speed, power, and efficiency, ensuring an upgraded computing experience like never before at ETechBuy.com" />
        <meta property="og:title" content="Maximize Performance with essential Computer Components | ETechBuy.com" />
        <meta property="og:image" content="https://etechbuy.com/favico.svg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="EtechBuy.com" />
        <link rel="canonical" href={canonicalURL} />
      </Helmet>
      <Routers />
    </>
  );
}

export default App;
