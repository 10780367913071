import React, {useEffect, useState} from 'react';
import { Tabs, Tab, Typography } from '@material-ui/core';
import {connect} from "react-redux";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import datas from "../../data/products.json";
import {getHomeBanner, getHomeTopManufacture} from "../../actions/homeAction";


const ManufactureProducts = (props) => {
    const { className, sectionTitle , type,} = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabs, setTabs] = useState([]);
    const [transformedProducts, setTransformedProducts] = useState([]);


    const handleTabChange = (event, newValue) => {
        const data = props.top_manufacturers?.data;
        // Assuming tabs array contains manufacturer names
        const selectedManufacturer = tabs[newValue];

        // Call the function and get products for the selected manufacturer
        const selectedManufacturerProducts = getProductsByManufacturer(data, selectedManufacturer);

        // Do something with the selected products, e.g., update state
        transformedProductData(selectedManufacturerProducts)

        // Update the selectedTab state or perform other actions as needed
        setSelectedTab(newValue);
    };

    function transformedProductData(selectedManufacturerProducts){
        if (selectedManufacturerProducts?.website_products && selectedManufacturerProducts?.website_products.length > 0) {
            const transformedArray = selectedManufacturerProducts.website_products.map((data) => {
                // Find the base image
                let baseImage = null;

                if (data.product.images) {
                    const images = Object.values(data.product.images);

                    for (const image of images) {
                        const customProperties = image.custom_properties;

                        if (customProperties && customProperties.image_type && customProperties.image_type.includes("Base")) {
                            baseImage = image.original_url;
                            break;
                        }
                    }
                }

                if(baseImage == null){
                    baseImage = data?.website_category?.image;
                }

                let sell_price = '';
                let special_price = '';
                if(data.sell_price !== null){
                    sell_price = "$"+data.sell_price
                }
                if(data.special_price !== null){
                    special_price = "$"+data.special_price
                }

                return {
                    id: data.id,
                    product_id: data?.product.id,
                    redirect_url: data?.redirect_url,
                    short_description: data.short_description,
                    website_category: data?.website_category,
                    long_description: data.long_description,
                    cost_price: data.cost_price,
                    sell_price: data.sell_price,
                    special_price: data.special_price,
                    discount: data.discount,
                    qty: data.qty,
                    meta_title: data.meta_title,
                    meta_description: data.meta_description,
                    meta_keywords: data.meta_keywords,
                    product: data.product,
                    attributes: data.attributes,
                    url: data.url,
                    title: data.title,
                    image: baseImage, // Use the base image if available, otherwise fallback to a default image
                    brand: "",
                    review: 0,
                    offer_price: sell_price,
                    price: special_price,
                    campaingn_product: false,
                    cam_product_available: null,
                    cam_product_sale: null,
                    product_type: null
                };
            });
            setTransformedProducts(transformedArray);
        }
    }

    useEffect(() => {
        const data = props.top_manufacturers?.data;
        const initialManufacturer = tabs[selectedTab];
        const initialManufacturerProducts = getProductsByManufacturer(data, initialManufacturer);
        transformedProductData(initialManufacturerProducts)
    }, [selectedTab, tabs, props.top_manufacturers]);

    useEffect(() => {
        // Fetch main category tree when the component mounts
        props.getHomeTopManufacture();
    }, []);

    useEffect(() => {
        const data = props.top_manufacturers?.data;
        const uniqueManufacturerNames = [...new Set(data.map(item => item.website_manufacturer?.title))];
        setTabs(uniqueManufacturerNames)
    }, [props.top_manufacturers]);


    function getProductsByManufacturer(data, manufacturerName) {
        // Find the manufacturer object based on the name
        const manufacturer = data.find((item) => item.website_manufacturer?.title === manufacturerName);

        // If the manufacturer is found, return its products; otherwise, return an empty array
        return manufacturer ? manufacturer : [];
    }

    // const {products} = datas;
    // const brands = [];
    // products.forEach((product) => {
    //     brands.push(product.brand);
    // });

    return (
        <div data-aos="fade-up" className={`w-full  ${className || ""}`}>

            <div className="container-x mx-auto ">
                <div>
                    <h1 className="sm:text-3xl text-xl font-600 mb-4 cateList text-qblacktext">
                        {props.sectionTitle}
                    </h1>
                </div>

                {/* Material-UI Tabs */}
                <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" className='tabsMan' >
                    {tabs.map((category, index) => (
                        <Tab key={index} label={category}  />
                    ))}
                </Tabs>

                {/* Content for each tab */}
                {tabs.map((category, index) => (
                    <div key={index} style={{ display: selectedTab === index ? 'block' : 'none' }}>
                        <Typography component="div">
                            <SectionStyleThree
                                products={transformedProducts}
                                // sectionTitle="New Arrivals"
                                seeMoreUrl="/all-products"
                                className="new-products mb-[60px]"
                            />
                        </Typography>
                    </div>
                ))}


            </div>

        </div>
    );
};

const mapStateToProps = state => ({
    top_manufacturers: state.home.topManufacturers,
});

const mapDispatchToProps = dispatch => ({
    getHomeTopManufacture: () => dispatch(getHomeTopManufacture()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ManufactureProducts);
