import React, { useState, useEffect, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import {cardPayment} from "../../actions/commonAction";
import {connect} from "react-redux";
import CheckoutForm from "./CheckoutStripeForm";
import "../CheakoutPage/stripeStyle.css";
const stripePromise = loadStripe('pk_live_51NLu19FEMymZSyKqbi6xZbo9zmQmbHVPa2KX9aOzA9wVPzjov7ec3RHJZ42w24L1caJehlcR1urda3VRgAir1VgK00LS0hjUVz');

const StripePaymentForm = (props) => {
    const { taxPer, username,cardPaymentStatus,discountAmount,paymentMethodData, taxAmount, totalPrice, fedexAmount, setCardPaymentStatus, paymentMethod, name, phone, email, addressLines, city, state, countryCode, setCardPaymentResponse } = props;
    const latestPaymentMethod = useRef(null);
    const latestUserName = useRef(null);
    const latestUserEmail = useRef(null);

    const totalPaymentStripe = useRef(0);
    const cardElement = useRef(null);
    const [cardPaymentSuccess, setCardPaymentSuccess] = useState("");
    const [cardPaymentError, setCardPaymentError] = useState("");
    const [paymentClientSecret, setPaymentClientSecret] = useState("");
    // const [clientSecret, setClientSecret] = useState(paymentMethodData.config.secret_key);

    useEffect(() => {
        let totalP = totalPrice - discountAmount
        var net_amount = (parseFloat(totalP) + parseFloat(fedexAmount) + parseFloat(taxAmount)).toFixed(2)
        totalPaymentStripe.current = net_amount;
        latestUserName.current = username;
        latestUserEmail.current = email;

    }, [discountAmount, taxAmount, totalPrice, fedexAmount,username,email]);


    useEffect(()=>{
        const data = {
            type: "stripe",
            amount: parseFloat(totalPaymentStripe.current).toFixed(2),
            currency: 'USD',
            customer_name : latestUserName.current,
            customer_email: latestUserEmail.current,
        };
        props.stripePayment(data);
        setPaymentClientSecret("");
    },[paymentMethod,fedexAmount])

    useEffect(() => {
        latestPaymentMethod.current = paymentMethod;
    }, [paymentMethod]);


    // const handleSubmit = async () => {
        // if (!clientSecret) {
        //     console.error('Client secret not available');
        //     // Handle the error (e.g., display user-friendly message)
        //     return;
        // }
        // const stripe = await stripePromise; // Use the same stripePromise instance
        //
        // const { token } = await stripe.createToken(cardElement.current);

        // if(token !== undefined){
        //     if (latestPaymentMethod.current  === 'stripe'){
        //         const data = {
        //             // type: "stripe",
        //             amount: Math.ceil(totalPaymentStripe.current),
        //             currency: 'USD',
        //             // token: token.id,
        //             customer_name : latestUserName.current,
        //             customer_email: latestUserEmail.current,
        //         };
        //         props.stripePayment(data);
        //     }
            // if (token.error) {
            //     console.error('Error creating token:', token.error);
            //     // Handle token creation errors (e.g., display user-friendly message)
            //     return;
            // }
        // }
    // };

    useEffect(()=>{
        if(paymentMethod === 'stripe'){
            if(props.card_stripe_res !== null && props.card_stripe_res !== undefined){
                if(props.card_stripe_res?.data?.data?.client_secret){
                    setPaymentClientSecret(props.card_stripe_res?.data?.data?.client_secret)
                }
            }
            // if(props.card_stripe_res?.data?.success !== ''){
            //     if(props.card_stripe_res?.data?.success === true){
            //         setCardPaymentSuccess("Payment Success");
            //         setCardPaymentError("");
            //         setCardPaymentResponse(props.card_stripe_res?.data?.data);
            //         setCardPaymentStatus(props.card_stripe_res?.data?.success);
            //     }else if(props.card_stripe_res?.success === false){
            //         setCardPaymentSuccess("");
            //         setCardPaymentError("Payment Failed!");
            //         setCardPaymentStatus(props.card_stripe_res?.data?.success);
            //         setCardPaymentResponse(props.card_stripe_res?.data?.data);
            //     }
            // }
        }
    },[props.card_stripe_res])

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        'clientSecret': paymentClientSecret,
        appearance,
    };

    return (
            <>
                {paymentClientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm setCardPaymentStatus={setCardPaymentStatus} cardPaymentStatus={cardPaymentStatus} amount={Math.ceil(totalPaymentStripe.current)} />
                    </Elements>
                )

                }
            </>

            // <div>
            //     <span className="text-success">{cardPaymentSuccess}</span>
            //     <span className="text-danger">{cardPaymentError}</span>
            // </div>

    );
};
const mapStateToProps = (state) => ({
    card_stripe_res: state.common.cardRes
});

const mapDispatchToProps = (dispatch) => ({
    stripePayment: (jsondata) => dispatch(cardPayment(jsondata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripePaymentForm);
