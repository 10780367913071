import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { getFaq, getReturnpolicies } from "../../actions/commonAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet-async";

const ReturnPolicies = (props) => {
  useEffect(() => {
    // Fetch main category tree when the component mounts
    props.getReturnpolicies();
  }, []);

  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Return Policy | Making Computing Better | ETechBuys.com </title>
        <meta property="og:title" content={'Return Policy | Making Computing Better | ETechBuys.com '} />
        <meta name="title" content={'Return Policy | Making Computing Better | ETechBuys.com '} />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Return Policy", path: "/return-policies" },
              ]}
              title="Return Policy"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Thank you for ordering at ETechBuys.com; we hope we fulfilled
                  your expectations. However, in case of any issue with the
                  order, you might want to initiate a return, refund, or
                  exchange, following our policies and guidelines in each case.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>Exchanges, Refunds or Restocking Policy</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The refund, exchange, or restocking can be catered in
                        the following scenarios:
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The part you received does not work
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        You ordered the wrong item (an error at the
                        customer&rsquo;s end)
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        You received the wrong product
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The part you received stopped working
                      </li>
                    </ul>
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">
                  In case of any of the mentioned scenarios, please refer to the
                  guidelines and policies below:
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Obtain a valid RMA number by emailing us at{" "}
                  <strong>rma@ETechBuys.com</strong> or call us at{" "}
                  <strong>(877) 801-9898. </strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Please ship the RMA package to the following address:
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>815 E St #12709 </strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>#16318 San Diego, CA 92112.</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>To get an exchange</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        All returns must be postmarked within thirty (30) days
                        of the purchase date. All returned items must be in
                        unused condition, with all original tags and labels
                        attached.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        The request for an exchange can be catered if initiated
                        within 30 days of receiving unless mentioned otherwise
                        on the product page.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        If you want to exchange a product, and the issue is due
                        to a customer's error, a restocking fee of 20-25% of the
                        merchandise value or a minimum of $25 will be applied.
                        You will also need to pay for the shipping cost of the
                        exchanged item.
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>To get a refund</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        To initiate a refund, please obtain the RMA number
                        postmarked within thirty (30) days of the purchase date.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        If the issue with a product is the customer's fault and
                        they want a refund, a restocking fee of 20-25% of the
                        item's value or a minimum of $25 will be charged.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Items that do not qualify for a refund (only exchange is
                        available): Special Order Items
                      </li>
                    </ul>
                  </li>

                  <li className="text-[15px] text-qgraytwo leading-7">
                    <strong>The Conditions and Guidelines for Returns</strong>
                    <ul className="list-disc ml-5">
                      <li className="text-[15px] text-qgraytwo leading-7">
                        For non-defective items with "Free Standard Shipping,"
                        ETechBuy will deduct the shipping cost from the refunded
                        amount.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Defective items with "Free Standard Shipping" qualify
                        for free one-way ground shipping for replacements.
                        Expedited delivery costs are the customer's
                        responsibility. If a replacement is unavailable, a full
                        refund will be issued.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        ETechBuy reserves the right to cancel orders for items
                        that are not in stock.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        If a replacement item is no longer available or out of
                        stock, the customer can choose a comparable replacement
                        or receive a refund at the current market value. The
                        refund will not exceed the original invoice price.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        RMA (Return Merchandise Authorization) items can only be
                        replaced with a similar or identical item. Exchanges for
                        different products are not allowed.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Customers can request an exchange RMA for products still
                        under warranty. The return must include all original
                        accessories and packaging.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Credit for refunds may take up to 5 working days to
                        reflect in banking records. Customers will be notified
                        of any delays.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Customers are advised to track returned packages. RMA
                        status will be marked as "In Process" once received.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        Limited warranties may apply to certain items. Customers
                        should review product pages and invoices for warranty
                        information.
                      </li>
                      <li className="text-[15px] text-qgraytwo leading-7">
                        ETechBuy is not liable for damage or issues during
                        product installation. Customers should contact
                        manufacturers for warranty claims.
                      </li>
                    </ul>
                  </li>
                </ul>
                <div id="pip-toast">&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  returnPolicies: state.common.returnPoliciesRes,
});

const mapDispatchToProps = (dispatch) => ({
  getReturnpolicies: () => dispatch(getReturnpolicies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPolicies);
