import { Link } from "react-router-dom";

export default function ProductCardRowStyleTwo({ className, datas }) {
  return (
    <div
      data-aos="fade-up"
      className={`product-card-row-two w-full  ${className || ""}`}
    >
      <div className="w-full h-[130px] bg-white border border-primarygray px-5 ">
        <div className="w-full h-full flex space-x-5 justify-center items-center">
          <div className="w-[75px] h-[75px]">
            <img
              src={`${datas.website_category_image}`}
              alt=""
              className="w-full h-full object-cover"
            
            />
          </div>
          <div className="flex-1 h-full flex flex-col justify-center">
            <Link to={`category/${datas.title}`} className="title-link">
              <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-1 hover:text-blue-600">
                {datas.title}
              </p>
            </Link>
            <ul className="price">
              {datas.children &&
                datas.children.map((data) => (
                  <Link
                    key={data.id}
                    to={`category/${data.url}`}
                    className="main-price font-400 text-[14px] "
                  >
                    <li>{data.title}</li>
                  </Link>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
