import {commonConstants, homeConstants, productConstants} from "../constants";
import axiosConfig from '../axiosConfig'

const websiteId  = 1;
export const getProducts = (filterData,page,per_page=12) => async (dispatch) => {

    try {
        const apiUrl = `/websites/${websiteId}/websiteproducts`;
        const filters = {
            'filter[active]': 1,
            'per_page': per_page, // replace per_page with the actual value you want
            'page': page,         // replace page with the actual value you want
        };

        if(filterData.type === "category"){
            if (filterData.categoryId !== null && filterData.categoryId !== undefined) {
                filters['filter[website_category_id]'] = filterData.categoryId;
            }
        }else if(filterData.type === "manufacture"){
            if (filters.manufactureId !== null && filterData.manufactureId !== undefined) {
                filters['filter[website_manufacturer_id]'] = filterData.manufactureId;
            }
        }else if(filterData.type === "categories"){
            let manufacture_id = filterData?.navbarDetail?.website_manufacturer?.id;
            let category_id = filterData?.navbarDetail?.website_category?.id;
            let attribute_id = filterData?.navbarDetail?.attribute?.id;
            let attribute_value = filterData?.navbarDetail?.attribute_value;
            let unit = filterData?.navbarDetail?.unit;

            if (manufacture_id !== null && manufacture_id !== undefined) {
                filters['filter[website_manufacturer_id]'] = manufacture_id;
            }

            if (category_id !== null && category_id !== undefined) {
                filters['filter[website_category_id]'] = category_id;
            }

            if (attribute_id !== null && attribute_id !== undefined && attribute_value !== null && attribute_value !== undefined) {
                let attributeFilter = `filter[attribute:${attribute_id}]`;
                let attributeValue = '';
                if (unit !== '' && unit !== null && unit !== undefined) {
                    attributeValue += `${attribute_value}:${unit.name}`;
                } else {
                    attributeValue += `${attribute_value}`;
                }
                filters[attributeFilter] = attributeValue;
            }

        }else if(filterData.type === 'search'){
            if (filters.title !== null && filterData.title !== undefined) {
                filters['filter[title]'] = filterData.title;
            }
        }

        // Parse and include the params from filterData
        const paramsFromFilterData = new URLSearchParams(filterData.params);
        paramsFromFilterData.forEach((value, key) => {
            if(value !== null && value !== undefined && value !== ""){
                filters[key] = value;
            }
        });
        const urlWithFilters = `${apiUrl}?${new URLSearchParams(filters).toString()}`;
        const res = await axiosConfig.get(urlWithFilters);

        dispatch({
            type: productConstants.PRODUCT,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: productConstants.PRODUCT_ERROR,
            payload: console.log(e),
        });
    }
};

export const getProductSearch = (filterData,per_page=5) => async (dispatch) => {

    try {
        const apiUrl = `/websites/${websiteId}/websiteproducts`;
        const page = 1;
        const filters = {
            'filter[active]':1,
            'per_page': per_page, // replace per_page with the actual value you want
            'page': page,         // replace page with the actual value you want
        };

        if (filterData.title !== null && filterData.title !== undefined) {
            filters['filter[title]'] = filterData.title;
        }

        const urlWithFilters = `${apiUrl}?${new URLSearchParams(filters).toString()}`;
        const res = await axiosConfig.get(urlWithFilters);

        dispatch({
            type: productConstants.PRODUCT_SEARCH,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: productConstants.PRODUCT_SEARCH_ERROR,
            payload: console.log(e),
        });
    }
};

export const getProductDetail = (url) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteproducts/detail?url=${url}`);
        dispatch({
            type: productConstants.PRODUCT_DETAIL,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: productConstants.PRODUCT_DETAIL_ERROR,
            payload: e.response.data ,
        });
    }
};

export const getProductFilters = (filter=null) => async (dispatch) => {

    try {
        const res = await axiosConfig.get(`/websites/${websiteId}/websiteproducts/filters?${filter}`);
        dispatch({
            type: productConstants.PRODUCT_FILTER,
            payload: res,
        });
    } catch (e) {
        dispatch({
            type: productConstants.PRODUCT_FILTER_ERROR,
            payload: console.log(e) ,
        });
    }
};