import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";

export default function Warranty() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>Warranty | Making Computing Better | ETechBuys.com </title>
        <meta property="og:title" content={'Warranty | Making Computing Better | ETechBuys.com'} />
        <meta name="title" content={'Warranty | Making Computing Better | ETechBuys.com'} />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Warranty", path: "/warranty" },
              ]}
              title="Warranty"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <ul className="text-[15px] text-qgraytwo leading-7 list-disc ml-5 mb-2">
                  <li>
                    ETechBuys.com ("ETechBuys.com") (the "company") is a reseller
                    and distributor, not a manufacturer, of the products it
                    sells. Here are key points regarding the company's policies
                    and disclaimers:
                  </li>
                  <li>
                    ETechBuys.com sells products not manufactured by the company.
                  </li>
                  <li>
                    The manufacturer's warranty may cover products, and
                    ETechBuys.com passes this warranty to the customer.
                  </li>
                  <li>
                    ETechBuys.com disclaims all warranties, express or implied,
                    regarding the products, except as explicitly stated in its
                    documents.
                  </li>
                  <li>
                    ETechBuys.com makes no guarantees about the uninterrupted,
                    timely, secure, or error-free nature of its services.
                  </li>
                  <li>
                    Users are responsible for material downloaded through
                    ETechBuys.com, and the company is not liable for any
                    resulting damage to their systems.
                  </li>
                  <li>
                    No advice or information from ETechBuys.com creates a
                    warranty unless expressly stated in the Terms and Conditions
                    of Use (TCUS).
                  </li>
                  <li>
                    ETechBuys.com is not liable for various damages arising from
                    the sale, installation, use, operation, service, or repair
                    of any product. It includes indirect, direct, incidental,
                    special, punitive, or consequential damages.
                  </li>
                  <li>
                    ETechBuys.com is an independent product reseller. The website
                    (ETechBuys.com) and its products are not authorized,
                    sponsored, endorsed, or approved by any original
                    manufacturer. Also, products available via this website may
                    be used, reconditioned, or refurbished by ETechBuys.com
                    and/or third-party vendors.
                  </li>
                  <li>
                    ETechBuys.com expressly disclaims all legal liability related
                    to personal injury or property loss arising from the use of
                    products/services purchased, including specific areas like
                    military, medical treatment, traffic control, disaster
                    prevention, or related equipment.
                  </li>
                  <li>
                    <strong>Exclusions: </strong>ETechBuy disclaims all
                    responsibility for any personal injuries or property damage
                    potentially resulting from the use of products/services
                    purchased, including, but not limited to, applications in
                    military, medical, traffic control, disaster prevention, or
                    any related equipment.
                  </li>
                  <li>Missing memory or flash cards resulting in faults</li>
                  <li>
                    Software-related issues (which will incur additional
                    charges)
                  </li>
                  <li>Any customer-induced damage or alterations</li>
                  <li>Parts not supplied by ETechBuy</li>
                  <li>Misuse, unauthorized repairs, or modifications</li>
                  <li>Natural or human-made disasters affecting the product</li>
                  <li>
                    Malfunctions due to unauthorized or self-compiled software
                  </li>
                  <li>Virus infections because of inadequate maintenance</li>
                  <li>Issues due to non-standard expansion cards</li>
                  <li>
                    Please report any damages that occurred during transit
                    within 2 working days of delivery, and provide pictures as
                    documentation. All valid claims will be processed upon
                    receipt of the returned component(s). Maintain the warranty
                    card along with a copy of the invoice for all warranty
                    claims
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  For more information or if you have questions, you can reach
                  us at:
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  <strong>Telephone</strong>: (877) 801-9898
                </p>
                <p className="text-[15px] text-qgraytwo leading-7 mb-1">
                  <strong>Email</strong>: support@etechbuys.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
