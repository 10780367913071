import { useEffect } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Helmet } from "react-helmet-async";

export default function TermsCondition() {
  // For Scroll Top
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />
        <title>
          Terms and Conditions | Making Computing Better | ETechBuys.com
        </title>
        <meta
          property="og:title"
          content={
            "Terms and Conditions | Making Computing Better | ETechBuys.com"
          }
        />
        <meta
          name="title"
          content={
            "Terms and Conditions | Making Computing Better | ETechBuys.com"
          }
        />
        <meta
          name="description"
          content="One-stop shop for Computer Hardware | Explore ETechBuy Policies"
        />
      </Helmet>
      <Layout childrenClasses="pt-0 pb-0">
        <div className="terms-condition-page w-full bg-white pb-[30px]">
          <div className="w-full mb-[30px]">
            <PageTitle
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "Terms and condition", path: "/terms-conditions" },
              ]}
              title="Terms and Condition"
            />
          </div>
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Terms and Conditions of Use (TCUS)</strong>
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Welcome to ETechBuys.com The following are the rules that
                  govern the use of the ETechBuys.com website (the "Site")
                  products and services. By accessing the Site, you agree to
                  abide by these TCUS, including our Privacy Policy and other
                  guidelines. Please review these terms regularly; we may update
                  the TCUS periodically. You must check this page from time to
                  time for changes and updates. Your use of the Site following
                  any posted change(s) to the TCUS will be deemed an acceptance
                  of all change(s).
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  Individuals who are thirteen and under are strictly and
                  expressly prohibited from posting any personally identifiable
                  information about themselves. This Site is not intended for
                  use by persons who are thirteen and under.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Site Access Rights and License</strong>
                </p>

                <p className="text-[15px] text-qgraytwo leading-7">
                  ETechBuy grants users a non-exclusive license for personal,
                  non-commercial use of the site, with strict prohibitions on.
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Resale of the Site or its contents.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Collection and use of product data listings, titles,
                    descriptions, or prices, except as expressly authorized.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Derivative use of the Site or its contents.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Use of data mining, robots, or similar tools for data
                    gathering and extraction.
                  </li>
                </ul>

                <p className="text-[15px] text-qgraytwo leading-7">
                  The Site, including its operations, interface, and contents,
                  is protected by United States copyright laws and international
                  treaties. Unauthorized exploitation of the site for commercial
                  purposes is strictly prohibited under copyright laws. Any
                  unauthorized use results in immediate termination of the
                  license and potential legal consequences. Users are allowed to
                  create hyperlinks to the site under certain conditions, but
                  the use of logos or trademarks requires explicit written
                  consent from the company or relevant partner. Using any
                  ETechBuy licensor, affiliate, subsidiary, co-brand partner, or
                  other partner logo or proprietary graphic or trademark as part
                  of a link to the site requires obtaining the express and
                  written consent of the Company, the subsidiary, affiliate,
                  co-brand partner, or other partner or licensor.
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Product Listings and Content</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy prioritizes accuracy in product information but
                    does not guarantee its accuracy, reliability, completeness,
                    currentness, or error-free nature.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The information is for informational purposes only and
                    should not be considered an endorsement by the Company.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy disclaims any responsibility or liability for
                    errors in the content.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The company reserves the right to refuse or cancel orders
                    due to inaccuracies.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy will promptly correct or remove incorrect
                    information as needed.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Links To Other Sites and Other Businesses</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The Site includes links to external sources such as
                    manufacturers, third-party blogs, product review sites,
                    affiliated companies, and partner sites.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    These links are provided solely for informational purposes
                    and do not imply the Company's endorsement of the content or
                    business practices of these third-party entities.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The Company disclaims responsibility for evaluating the
                    content or businesses linked on the Site. It does not
                    warrant or assume responsibility for the products, services,
                    offerings, or practices of these entities.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Users are strongly encouraged to review the terms,
                    conditions, and privacy statements of linked sites, as the
                    Company lacks control over their quality, safety, legality,
                    or business transactions.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In the event of disputes between users and hyperlinked
                    websites through ETechBuys.com, users release ETechBuy, Inc.,
                    its affiliates, subsidiaries, partners, or other contracted
                    third parties, along with their officers, directors,
                    employees, agents, and legal representatives, from all
                    claims and damages, whether known or unknown, suspected or
                    unsuspected, disclosed or undisclosed, arising from or
                    connected to such disputes.
                  </li>
                </ul>

                <p className="text-[15px] text-qgraytwo leading-7">
                  If you are a California resident, by using the Site, you
                  explicitly waive the rights granted to you under California
                  Civil Code 1542, which states: "A general release does not
                  extend to claims which the creditor does not know or suspect
                  to exist in his favor at the time of executing the release,
                  which, if known by him, must have materially affected his
                  settlement with the debtor".
                </p>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Trademarks</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The Trademarks (trademarks, service marks, logos, and
                    graphics) on the ETechBuy Internet Site are registered
                    trademarks of ETechBuy.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Any use or linking to ETechBuy Trademarks or Site(s) must
                    comply with the terms and conditions outlined in the Terms
                    and Conditions of Use Statement (TCUS).
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Purchasing the ETechBuy name, trademark, or language syntax
                    is prohibited.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Creating derivatives of the ETechBuy name or Trademark is
                    not allowed.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Purchasing keywords to redirect traffic to competing
                    businesses using ETechBuy's name is strictly prohibited.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Order Acknowledgement</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Order acknowledgment is not acceptance but a record of the
                    customer's offer.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy has the right to determine order fulfillment and
                    may refuse or limit orders for undisclosed reasons.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Placing an order implies acceptance of all Terms and
                    Conditions (TCUS), including ETechBuy's sales terms.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy reserves the right to accept, refuse, or limit
                    orders based on factors like credit review, product
                    availability, or errors in posted prices and descriptions.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Customers agree that ETechBuy's liability is limited to the
                    actual purchase price, excluding non-refundable shipping and
                    handling costs.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Prices</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Prices quoted on the Site are in US Dollars.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Prices exclude taxes, duties, customs clearing or forwarding
                    charges for international orders, freight, and handling
                    charges.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Sales tax is applicable to all products sold to Californian
                    customers.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    California resellers must provide a valid resale tax
                    exemption certificate.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Providing this document is essential to waive California
                    Sales Tax.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Failure to provide the required document invalidates any tax
                    exemptions the reseller may be eligible for.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy may revise and discontinue products or change
                    prices at any time without prior notice to customers.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Delivery Issues</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Shipment of all products is FOB (Free On Board) ETechBuy
                    warehouse unless otherwise indicated.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In the event of damage, delay, or loss due to transportation
                    mishandling, customers must directly engage with the common
                    freight carrier.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Customers can provide special shipping instructions during
                    order placement.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In the absence of specific instructions, the company will
                    choose the most reasonable shipping method for optimal
                    delivery.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy is not liable for delay, damage, or any
                    freight-related issues.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In case of shipment delay due to the customer, ETechBuy will
                    store ordered products at the customer's risk and expense.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Risk Of Loss</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Any risk of loss or damage to products will be passed to the
                    customer, customer's agent, and/or freight carrier in the
                    case of FOB shipping arrangement.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Product Disclaimer: Specify Part Number </strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Upon ordering the model part you'll receive a
                    top-performing part number within the chosen model range,
                    the specific part number may vary. However, if you have a
                    specific part number preference within the same model range,
                    kindly specify it to avoid any confusion. Our team will do
                    their best to accommodate your request.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Order Cancellation</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Orders accepted by ETechBuy cannot be changed or canceled by
                    a the customer without written consent from ETechBuy or its
                    authorized agents.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    A minimum handling fee, greater of $25 or fifteen percent
                    (15%) of the merchandise value, applies to all changed or
                    canceled orders.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Email cancellation requests are not accepted; customers must
                    call at (877) 801-9898, Monday - Friday, 9:00AM - 4:30 PM, to
                    change or cancel an order.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy may void or cancel an order at its discretion in
                    case of significant back-order status or non-availability of
                    products.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Customers should be aware that not every item on the website
                    may be available or in stock.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Based on item availability, ETechBuy may cancel orders for
                    items not in stock, apologizing for any resulting
                    inconvenience.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    If ETechBuy no longer carries an item sent for replacement
                    or if it's out of stock, two options are offered: a
                    comparable replacement item or a refund at the current
                    market value, not exceeding the original invoice price.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Jurisdiction and Venue</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In the event of legal action to interpret or enforce the
                    terms of this Agreement, jurisdiction and venue shall be in
                    the Municipal or Superior Courts of the County of Orange,
                    State of California.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Alternatively, legal proceedings may take place in the
                    United States District Court, Central District of
                    California.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The option of pursuing legal action elsewhere is available
                    upon mutual agreement of all parties involved.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Governing Law</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    All questions regarding the construction of this Agreement
                    and the rights and liabilities of the parties will be
                    determined in accordance with the applicable provisions of
                    the law of the State of California.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Choice of law principles will not be considered in this
                    determination.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Attorney's Fees</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In the event of legal action, arbitration, or other
                    proceedings related to the interpretation, enforcement, or
                    alleged dispute, breach, or default of this Agreement or any
                    related agreement, the successful or prevailing party is
                    entitled to recover reasonable attorney's fees, expenses,
                    and costs associated with enforcement proceedings.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Dropship Requests for Product by Customers</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    If a customer requests ETechBuy to ship a product to a third
                    party, both the customer and the final recipient third party
                    will be bound by the terms of this Agreement.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Conflicting Terms</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    All orders accepted by ETechBuy are subject to the TCUS.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Any terms and conditions in customer-placed orders
                    conflicting with the TCUS are inapplicable without written
                    approval from ETechBuy.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Confirmation</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Customers' use of the Site constitutes receipt of the TCUS
                    contained herein.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    The absence of prompt written objection or an acceptance of
                    any confirmed order implies acceptance of all terms and
                    conditions outlined on the Site.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Purchase Money Security Interest</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Upon delivery and customer acceptance, as security for
                    timely payment under any purchase order, the customer grants
                    ETechBuy a "Continuing Purchase Money Security Interest" in
                    all products and their proceeds, both current and future.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    At ETechBuy's request, customers will join in executing
                    financing statements, amendments, and continuation
                    statements, bearing the associated costs. They will also
                    execute and deliver any required documents and instruments,
                    and comply with reasonable requests to fulfill the purpose
                    of this Security Interest grant.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    In the event of default under the security agreement,
                    ETechBuy is entitled to all rights and remedies provided by
                    the law.
                  </li>
                </ul>
                <p className="text-[15px] text-qgraytwo leading-7">&nbsp;</p>
                <p className="text-[15px] text-qgraytwo leading-7">
                  <strong>Patents</strong>
                </p>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    ETechBuy explicitly disclaims any representation regarding
                    its intellectual property rights in any product or service
                    listed for sale on the Site.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
