import {commonConstants, productConstants} from "../../constants";

const initialState = {
    productRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    productSearchRes: {
        data: [],
        links: {},
        meta: {},
        loading: true,
    },
    productDetailRes: {
        data: {},
    },
    productFilterRes: {
        data: [],
    },

};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case productConstants.PRODUCT:
            return {
                ...state,
                productRes: action.payload.data,
                // loading: false,
            };
        case productConstants.PRODUCT_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case productConstants.PRODUCT_SEARCH:
            return {
                ...state,
                productSearchRes: action.payload.data,
                // loading: false,
            };
        case productConstants.PRODUCT_SEARCH_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        case productConstants.PRODUCT_DETAIL:
            return {
                ...state,
                productDetailRes: action.payload.data,
                loading: false,
            };
        case productConstants.PRODUCT_DETAIL_ERROR:
            return {
                ...state,
                productDetailRes: action.payload,
                loading: false,
            };
        case productConstants.PRODUCT_FILTER:
            return {
                ...state,
                productFilterRes: action.payload.data,
                loading: false,
            };
        case productConstants.PRODUCT_FILTER_ERROR:
            return {
                ...state,
                // Handle error state as needed
            };
        default:
            return state;
    }
};

export default productReducer;
